export type Language = "en" | "es" | "ca" | "pt" | "de" | "fr" | "da" | "it" | "nl" | "nn" | "zh" | "ja" | "ru" | "tr" | "pl" | "fi" | "ar" | "sv";

// Mapping from internal language codes to full language names or descriptions for dynamic language instruction
export const languageDescriptions: Record<Language, string> = {
  en: "English",
  es: "Spanish",
  ca: "Catalan",
  pt: "Portuguese",
  de: "German",
  fr: "French",
  da: "Danish",
  it: "Italian",
  nl: "Dutch",
  nn: "Norwegian",
  zh: "Chinese",
  ja: "Japanese",
  ru: "Russian",
  tr: "Turkish",
  pl: "Polish",
  fi: "Finnish",
  ar: "Arabic",
  sv: "Swedish",
};
