import {
  GET_THOMAS_USERS,
  GET_THOMAS_USERS_SUCCESS,
  GET_THOMAS_USERS_FAIL,
  ADD_NEW_THOMAS_USER,
  ADD_THOMAS_USER_SUCCESS,
  ADD_THOMAS_USER_FAIL,
  UPDATE_THOMAS_USER,
  UPDATE_THOMAS_USER_SUCCESS,
  UPDATE_THOMAS_USER_FAIL,
  DELETE_THOMAS_USER,
  DELETE_THOMAS_USER_SUCCESS,
  DELETE_THOMAS_USER_FAIL,
  GET_THOMAS_USER_PROFILE,
  GET_THOMAS_USER_PROFILE_SUCCESS,
  GET_THOMAS_USER_PROFILE_FAIL,
  GET_USER_EVENTS,
  GET_USER_EVENTS_SUCCESS,
  GET_USER_EVENTS_FAIL,
  ADD_USER_EVENT,
  ADD_USER_EVENT_SUCCESS,
  ADD_USER_EVENT_FAIL,
  UPDATE_USER_EVENT,
  UPDATE_USER_EVENT_SUCCESS,
  UPDATE_USER_EVENT_FAIL,
  DELETE_USER_EVENT,
  DELETE_USER_EVENT_SUCCESS,
  DELETE_USER_EVENT_FAIL,
  SELECT_THOMAS_USER,
  SELECT_THOMAS_USER_SUCCESS,
  SELECT_THOMAS_USER_FAIL,
  ACCEPT_USER_INTO_EVENT,
  ACCEPT_USER_INTO_EVENT_SUCCESS,
  ACCEPT_USER_INTO_EVENT_FAIL,
  GET_NEARBY_EVENTS,
  GET_NEARBY_EVENTS_SUCCESS,
  GET_NEARBY_EVENTS_FAIL,
} from "./actionTypes";

export const getThomasUsers = () => ({
  type: GET_THOMAS_USERS,
})

export const getThomasUsersSuccess = users => ({
  type: GET_THOMAS_USERS_SUCCESS,
  payload: users,
})

export const addNewThomasUser = user => ({
  type: ADD_NEW_THOMAS_USER,
  payload: user,
})

export const addThomasUserSuccess = user => ({
  type: ADD_THOMAS_USER_SUCCESS,
  payload: user,
})

export const addThomasUserFail = error => ({
  type: ADD_THOMAS_USER_FAIL,
  payload: error,
})

export const getThomasUsersFail = error => ({
  type: GET_THOMAS_USERS_FAIL,
  payload: error,
})

export const getThomasUserProfile = () => ({
  type: GET_THOMAS_USER_PROFILE,
})

export const getThomasUserProfileSuccess = userProfile => ({
  type: GET_THOMAS_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getThomasUserProfileFail = error => ({
  type: GET_THOMAS_USER_PROFILE_FAIL,
  payload: error,
})

export const updateThomasUser = user => ({
  type: UPDATE_THOMAS_USER,
  payload: user,
})

export const updateThomasUserSuccess = user => ({
  type: UPDATE_THOMAS_USER_SUCCESS,
  payload: user,
})

export const updateThomasUserFail = error => ({
  type: UPDATE_THOMAS_USER_FAIL,
  payload: error,
})

export const deleteThomasUser = user => ({
  type: DELETE_THOMAS_USER,
  payload: user,
})

export const deleteThomasUserSuccess = user => ({
  type: DELETE_THOMAS_USER_SUCCESS,
  payload: user,
})

export const deleteThomasUserFail = error => ({
  type: DELETE_THOMAS_USER_FAIL,
  payload: error,
})

export const getUserEvents = (userId) => ({
  type: GET_USER_EVENTS,
  payload: { userId },
});

export const getUserEventsSuccess = (events, userId) => ({
  type: GET_USER_EVENTS_SUCCESS,
  payload: { events, userId },
});

export const getUserEventsFail = (error) => ({
  type: GET_USER_EVENTS_FAIL,
  payload: error,
});

export const addUserEvent = (data) => ({
  type: ADD_USER_EVENT,
  payload: {
    event: data.event,
    user: data.user
  },
});

export const addUserEventSuccess = (event, userId) => ({
  type: ADD_USER_EVENT_SUCCESS,
  payload: { event, userId },
});

export const addUserEventFail = (error) => ({
  type: ADD_USER_EVENT_FAIL,
  payload: error,
});

export const updateUserEvent = (event, user) => ({
  type: UPDATE_USER_EVENT,
  payload: { event, user },
});

export const deleteUserEvent = (eventId, user) => ({
  type: DELETE_USER_EVENT,
  payload: { eventId, user },
});

export const acceptUserIntoEvent = (eventId, userId, user) => ({
  type: ACCEPT_USER_INTO_EVENT,
  payload: { eventId, userId, user },
});

export const updateUserEventSuccess = (event, userId) => ({
  type: UPDATE_USER_EVENT_SUCCESS,
  payload: { event, userId },
});

export const updateUserEventFail = (error) => ({
  type: UPDATE_USER_EVENT_FAIL,
  payload: error,
});

export const deleteUserEventSuccess = (eventId, userId) => ({
  type: DELETE_USER_EVENT_SUCCESS,
  payload: { eventId, userId },
});

export const deleteUserEventFail = (error) => ({
  type: DELETE_USER_EVENT_FAIL,
  payload: error,
});

export const selectThomasUser = (user) => ({
  type: SELECT_THOMAS_USER,
  payload: user,
});

export const selectThomasUserSuccess = (user) => ({
  type: SELECT_THOMAS_USER_SUCCESS,
  payload: user,
});

export const selectThomasUserFail = (error) => ({
  type: SELECT_THOMAS_USER_FAIL,
  payload: error,
});

export const acceptUserIntoEventSuccess = (event, userId) => ({
  type: ACCEPT_USER_INTO_EVENT_SUCCESS,
  payload: { event, userId },
});

export const acceptUserIntoEventFail = (error) => ({
  type: ACCEPT_USER_INTO_EVENT_FAIL,
  payload: error,
});

export const getNearbyEvents = (payload) => ({
  type: GET_NEARBY_EVENTS,
  payload,
});

export const getNearbyEventsSuccess = (events, userId) => ({
  type: GET_NEARBY_EVENTS_SUCCESS,
  payload: { events, userId },
});

export const getNearbyEventsFail = (error) => ({
  type: GET_NEARBY_EVENTS_FAIL,
  payload: error,
});
