import { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUserEvent,
} from '../store/thomas/actions';
import {
  GET_USER_EVENTS_SUCCESS,
} from '../store/thomas/actionTypes';
import {
  fetchThomasUserEventsAPI,
  getEventsNearbyByUser,
  askToJoinEvent,
  acceptUserIntoEventApi,
} from '../helpers/thomas_helper';
import {
  generateEventDetails,
  prepareEventData,
} from '../helpers/chat/chatGPT/generateEventDetails';
import { checkChatsAndReply } from '../helpers/chat/chatLogic';
import { getValidAccessToken } from '../helpers/accessTokenHelper';
import {
  shouldCreateEvent,
  shouldAskToJoinEvent,
  shouldAcceptUserIntoEvent,
} from '../utils/decisionLogic';
import {
  createChat,
  saveChatIdOnEvent,
  getChatData,
} from '../helpers/chat/chatGPT/chatManagement';
import {actionMessages} from '../utils/actionMessages';

// **Function to Get Action Messages**
function getActionMessage(key, ...args) {
  const message = actionMessages[key];
  if (typeof message === 'function') {
    return message(...args);
  }
  return message;
}

const useStartThomas = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.thomas);

  const [starting, setStarting] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  const isRunningRef = useRef(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // **Helper: Process Accepting Users into Events**
  const processAcceptingUsers = useCallback(
    async (user, userEvents, actionDelay) => {
      for (const event of userEvents) {
        if (!isRunning) break; // Check if Thomas should stop
        if (
          Array.isArray(event.users.waitList) &&
          event.users.waitList.length > 0
        ) {
          setCurrentAction(
            getActionMessage('processingWaitlist', event.title, user.firstName)
          );
          for (const pendingUser of event.users.waitList) {
            if (!isRunning) break; // Check if Thomas should stop

            const pendingUserId = pendingUser._id;
            // Decide whether to accept the user into the event
            const shouldAccept = shouldAcceptUserIntoEvent(
              event,
              user,
              pendingUserId
            );

            if (shouldAccept) {
              try {
                // Accept the user into the event
                await acceptUserIntoEventApi(event._id, pendingUserId, user);

                setCurrentAction(
                  getActionMessage(
                    'acceptedUser',
                    pendingUser.firstName,
                    event.title
                  )
                );

                await delay(actionDelay);
              } catch (error) {
                console.error(
                  `Error accepting user ${pendingUserId} into event "${event.title}":`,
                  error
                );
                setCurrentAction(
                  getActionMessage(
                    'failedToAcceptUser',
                    pendingUser.firstName,
                    event.title
                  )
                );
              }
            } else {
              setCurrentAction(
                getActionMessage(
                  'decidedNotToAcceptUser',
                  pendingUser.firstName,
                  event.title
                )
              );
            }
          }
        }
      }
    },
    [isRunning]
  );

  // **Helper: Create a New Event**
  const createNewEvent = useCallback(
    async (user, actionDelay) => {
      if (!isRunning) return; // Check if Thomas should stop
      setCurrentAction(
        getActionMessage('decidedToCreateEvent', user.firstName, user.lastName)
      );

      await delay(actionDelay);

      setCurrentAction(
        getActionMessage('generatingEventDetails', user.firstName, user.lastName)
      );
      const eventDetails = await generateEventDetails(user);

      if (eventDetails) {
        const eventData = await prepareEventData(user, eventDetails);

        setCurrentAction(
          getActionMessage('creatingEvent', user.firstName, user.lastName)
        );
        try {
          await dispatch(addUserEvent({ event: eventData, user }));

          setCurrentAction(
            getActionMessage(
              'eventCreated',
              eventData.title,
              user.firstName,
              user.lastName
            )
          );
        } catch (error) {
          console.error('Error creating event:', error);
          setCurrentAction(
            getActionMessage('failedToCreateEvent', user.firstName, user.lastName)
          );
        }
      } else {
        setCurrentAction(getActionMessage('noEventDetails', user.firstName));
      }
    },
    [dispatch, isRunning]
  );

  // **Helper: Ask to Join an Event**
  const askToJoinExistingEvent = useCallback(
    async (user, nearbyEvents, actionDelay) => {
      if (!isRunning) return; // Check if Thomas should stop
      if (nearbyEvents.length > 0) {
        const shouldAsk = shouldAskToJoinEvent(user, nearbyEvents);
        const { firstName } = user;

        if (shouldAsk) {
          const eventToJoin =
            nearbyEvents[Math.floor(Math.random() * nearbyEvents.length)];
            const { title, _id } = eventToJoin;

          setCurrentAction(
            getActionMessage('askingToJoinEvent', firstName, title)
          );

          await delay(actionDelay);

          try {
            await askToJoinEvent(_id, user);
            setCurrentAction(
              getActionMessage('askedToJoinEvent', firstName, title)
            );
          } catch (error) {
            console.error(`Error asking to join event:`, error);
            setCurrentAction(
              getActionMessage(
                'failedToAskToJoinEvent',
                firstName,
                title
              )
            );
          }
        } else {
          setCurrentAction(getActionMessage('decidedNotToAskToJoin', firstName));
        }
      } else {
        setCurrentAction(getActionMessage('noNearbyEvents', firstName));
      }
    },
    [isRunning]
  );

  const processChats = useCallback(
    async (user, actionDelay) => {
      if (!isRunning) return; // Check if Thomas should stop
      setCurrentAction(getActionMessage('checkingChats', user.firstName, user.lastName));

      try {
        const userEvents = await fetchThomasUserEventsAPI(user);

        for (const event of userEvents) {
          if (!isRunning) break; // Check if Thomas should stop
          if (event.chatId) {
            const chatData = await getChatData(event.chatId);
            if (!chatData) {
              console.warn(`Chat data not found for chatId: ${event.chatId}`);
              continue;
            }
            await checkChatsAndReply(user, event);
          } else {
            const chatUsers = event.users.active;
            if (chatUsers.length < 2) {
              continue;
            } else {
              const chatData = await createChat(user._id, { users: chatUsers });

              await saveChatIdOnEvent(event._id, chatData.key);
              await checkChatsAndReply(user, {
                ...event,
                chatId: chatData.key,
              });
            }
          }

          await delay(actionDelay); // Delay between processing each event
        }

        setCurrentAction(
          getActionMessage('completedChatInteractions', user.firstName, user.lastName)
        );
      } catch (error) {
        console.error(
          `Error interacting with chats for user ${user.firstName}:`,
          error
        );
        setCurrentAction(getActionMessage('errorInteractingWithChats', user.firstName));
      }

      await delay(actionDelay);
    },
    [isRunning, dispatch]
  );

  // **Start Thomas AI Processing**
  const startThomas = useCallback(() => {
    if (!users || users.length === 0) {
      setCurrentAction('No users available to process.');
      return;
    }

    setIsRunning(true);
  }, [users]);

  // **Stop Thomas AI Processing**
  const stopThomas = useCallback(() => {
    setIsRunning(false);
    setStarting(false);
    setCurrentAction(getActionMessage('thomasStopped'));
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const runThomas = async () => {
      if (isRunningRef.current) {
        console.log('Thomas is already running');
        return;
      }
      isRunningRef.current = true;

      try {
        while (isRunning && !isCancelled) {
          setStarting(true);
          setCurrentAction(getActionMessage('initializing'));

          try {
            for (const user of users) {
              if (!isRunning || isCancelled) break; // Check if Thomas should stop

              setCurrentAction(
                getActionMessage('processingUser', user.firstName, user.lastName)
              );
              console.log(`Processing user: ${user.firstName} ${user.lastName}`);

              const actionDelay = 5000; // 5 seconds delay between actions

              try {
                user.accessToken = await getValidAccessToken(user);
              } catch (error) {
                console.error(
                  `Error refreshing access token for user ${user.firstName}:`,
                  error
                );
                setCurrentAction(
                  getActionMessage(
                    'failedToRefreshToken',
                    user.firstName,
                    user.lastName
                  )
                );
                continue; // Skip to next user
              }

              const userEvents = await fetchThomasUserEventsAPI(user).catch(
                (error) => {
                  console.error(
                    `Error fetching events for user ${user.firstName}:`,
                    error
                  );
                  return [];
                }
              );

              // Dispatch action to store user events
              dispatch({ type: GET_USER_EVENTS_SUCCESS, payload: userEvents });

              // Handle accepting users into events
              await processAcceptingUsers(user, userEvents, actionDelay);

              // Decide whether to create a new event
              if (shouldCreateEvent(user, userEvents)) {
                await createNewEvent(user, actionDelay);
              } else {
                setCurrentAction(
                  getActionMessage(
                    'decidedNotToCreateEvent',
                    user.firstName,
                    user.lastName
                  )
                );

                await delay(actionDelay);

                try {
                  setCurrentAction(
                    getActionMessage(
                      'fetchingNearbyEvents',
                      user.firstName,
                      user.lastName
                    )
                  );

                  const { coordinates } = user.location || {};
                  const latitude = coordinates ? coordinates[1] : null;
                  const longitude = coordinates ? coordinates[0] : null;

                  const nearbyEvents = await getEventsNearbyByUser(
                    {
                      userId: user._id,
                      latitude,
                      longitude,
                      radius: 5000, // Adjust as needed
                    },
                    user
                  ).catch((error) => {
                    console.error(
                      `Error fetching nearby events for ${user.firstName}:`,
                      error
                    );
                    return [];
                  });

                  if (nearbyEvents.length > 0) {
                    await askToJoinExistingEvent(user, nearbyEvents, actionDelay);
                  } else {
                    setCurrentAction(getActionMessage('noNearbyEvents', user.firstName));
                  }
                } catch (error) {
                  console.error(
                    `Error fetching nearby events for ${user.firstName}:`,
                    error
                  );
                  setCurrentAction(
                    getActionMessage('errorFetchingNearbyEvents', user.firstName)
                  );
                }
              }

              // Interact with chats
              await processChats(user, actionDelay);
            }
          } catch (error) {
            console.error('An error occurred during Thomas AI execution:', error);
            setCurrentAction('An error occurred during Thomas AI execution.');
          } finally {
            setCurrentAction(null);
            setStarting(false);
            setCurrentAction(getActionMessage('processCompleted'));

            // Wait for 5 seconds before starting the next cycle
            await delay(5000);
          }
        }
      } finally {
        isRunningRef.current = false;
      }
    };

    if (isRunning && !isCancelled) {
      runThomas();
    }

    return () => {
      isCancelled = true;
      isRunningRef.current = false;
    };
  }, [
    isRunning,
    dispatch,
    users,
    processAcceptingUsers,
    createNewEvent,
    askToJoinExistingEvent,
    processChats,
  ]);

  return {
    startThomas,
    stopThomas,
    starting,
    currentAction,
    isRunning,
  };
};

export default useStartThomas;
