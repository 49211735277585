import { call, put, takeEvery } from "redux-saga/effects";

// Redux action types
import {
  GET_THOMAS_USERS,
  GET_THOMAS_USER_PROFILE,
  ADD_NEW_THOMAS_USER,
  DELETE_THOMAS_USER,
  UPDATE_THOMAS_USER,
  GET_USER_EVENTS,
  ADD_USER_EVENT,
  UPDATE_USER_EVENT,
  DELETE_USER_EVENT,
  SELECT_THOMAS_USER,
  ACCEPT_USER_INTO_EVENT,
  GET_NEARBY_EVENTS,
  GET_NEARBY_EVENTS_SUCCESS,
  GET_NEARBY_EVENTS_FAIL,
} from "./actionTypes";

// Redux actions
import {
  getThomasUsersSuccess,
  getThomasUsersFail,
  getThomasUserProfileSuccess,
  getThomasUserProfileFail,
  addThomasUserSuccess,
  addThomasUserFail,
  updateThomasUserSuccess,
  updateThomasUserFail,
  deleteThomasUserSuccess,
  deleteThomasUserFail,
  getUserEventsSuccess,
  getUserEventsFail,
  addUserEventSuccess,
  addUserEventFail,
  updateUserEventSuccess,
  updateUserEventFail,
  deleteUserEventSuccess,
  deleteUserEventFail,
  acceptUserIntoEventSuccess,
  acceptUserIntoEventFail,
  getNearbyEventsSuccess,
  getNearbyEventsFail
} from "./actions";

import {
  getUserProfile,
  deleteUser,
  getUsers,
  addNewUser,
} from "../../helpers/users_helper";

import {
  updateUser,
  addNewThomasUser,
  fetchUserEvents,
  createUserEvent,
  updateUserEvent,
  deleteUserEvent,
  acceptUserIntoEventApi,
  getEventsNearbyByUser
} from "../../helpers/thomas_helper";

// Toast notifications
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Fetch Thomas users
function* fetchThomasUsers({ payload = { filters: { isThomas: true } } }) {
  try {
    const response = yield call(getUsers, payload);

    yield put(getThomasUsersSuccess(response?.data));
  } catch (error) {
    yield put(getThomasUsersFail(error));
    toast.error("Failed to fetch Thomas users.", { autoClose: 2000 });
  }
}

// Fetch Thomas user profile
function* fetchThomasUserProfile() {
  try {
    const response = yield call(getUserProfile);
    yield put(getThomasUserProfileSuccess(response));
  } catch (error) {
    yield put(getThomasUserProfileFail(error));
    toast.error("Failed to fetch user profile.", { autoClose: 2000 });
  }
}

// Add new Thomas user
function* onAddNewThomasUser({ payload: user }) {
  try {
    const response = yield call(addNewThomasUser, user);
    yield put(addThomasUserSuccess(response));
    toast.success("User added successfully.", { autoClose: 2000 });

    // Refetch users after adding a new user
    yield put({ type: GET_THOMAS_USERS });
  } catch (error) {
    yield put(addThomasUserFail(error));
    toast.error("Failed to add user.", { autoClose: 2000 });
  }
}

// Update Thomas user
function* onUpdateThomasUser({ payload: user }) {
  try {
    console.log("onUpdateThomasUser", user);

    const response = yield call(updateUser, user);
    console.log("onUpdateThomasUser", response);

    if (response && response._id) {
      yield put(updateThomasUserSuccess(response));
      toast.success("User updated successfully.", { autoClose: 2000 });

      // Refetch users after updating a user
      yield put({ type: GET_THOMAS_USERS });
    } else {
      // Handle unexpected response format
      throw new Error("Unexpected response format.");
    }
  } catch (error) {
    yield put(updateThomasUserFail(error));
    toast.error("Failed to update user.", { autoClose: 2000 });
  }
}

// Delete Thomas user
function* onDeleteThomasUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user);
    yield put(deleteThomasUserSuccess(response));
    toast.success("User deleted successfully.", { autoClose: 2000 });

    // Refetch users after deleting a user
    yield put({ type: GET_THOMAS_USERS });
  } catch (error) {
    yield put(deleteThomasUserFail(error));
    toast.error("Failed to delete user.", { autoClose: 2000 });
  }
}

// Fetch user events
function* fetchThomasUserEvents({ payload }) {
  try {
    const response = yield call(fetchUserEvents, payload);

    yield put(getUserEventsSuccess(response?.data, payload.userId));
  } catch (error) {
    yield put(getUserEventsFail(error));
    toast.error("Failed to fetch user events.", { autoClose: 2000 });
  }
}

// Add user event
function* onAddUserEvent({ payload }) {
  try {
    // TODO: check this
    console.log("onAddUserEvent", payload);
    const { event, user } = payload;

    const response = yield call(createUserEvent, event, user);
    yield put(addUserEventSuccess(response));
    toast.success("Event added successfully.", { autoClose: 2000 });

    // Refetch users after adding a new event
    yield put({ type: GET_THOMAS_USERS });

    // Optionally, refetch specific user's events
    yield put({ type: GET_USER_EVENTS, payload: { userId: user._id } });
  } catch (error) {
    yield put(addUserEventFail(error));
    toast.error("Failed to add event.", { autoClose: 2000 });
  }
}

function* onUpdateUserEvent({ payload }) {
  try {
    const { event, user } = payload;
    const response = yield call(updateUserEvent, event, user);
    yield put(updateUserEventSuccess(response, user._id));
    toast.success("Event updated successfully.", { autoClose: 2000 });

    // Refetch users after updating an event
    yield put({ type: GET_THOMAS_USERS });

    // Refetch specific user's events
    yield put({ type: GET_USER_EVENTS, payload: { userId: user._id } });
  } catch (error) {
    yield put(updateUserEventFail(error));
    toast.error("Failed to update event.", { autoClose: 2000 });
  }
}

// Delete user event saga
function* onDeleteUserEvent({ payload }) {
  try {
    const { eventId, user } = payload;
    console.log("onDeleteUserEvent", payload);

    const response = yield call(deleteUserEvent, eventId, user);
console.log("onDeleteUserEvent", response);

    yield put(deleteUserEventSuccess(eventId, user._id));
    toast.success("Event deleted successfully.", { autoClose: 2000 });

    // Refetch users after deleting an event
    yield put({ type: GET_THOMAS_USERS });
  } catch (error) {
    yield put(deleteUserEventFail(error));
    toast.error("Failed to delete event.", { autoClose: 2000 });
  }
}

// Accept user into event saga
function* onAcceptUserIntoEvent({ payload }) {
  try {
    const { eventId, userId, user } = payload;
    console.log("onAcceptUserIntoEvent - user", user);

    const response = yield call(acceptUserIntoEventApi, eventId, userId, user);
    yield put(acceptUserIntoEventSuccess(response, userId));
    toast.success("User accepted into the event.", { autoClose: 2000 });

    // Refetch users and events
    yield put({ type: GET_THOMAS_USERS });
    yield put({ type: GET_USER_EVENTS, payload: { userId: user._id } });
  } catch (error) {
    yield put(acceptUserIntoEventFail(error));
    toast.error("Failed to accept user into the event.", { autoClose: 2000 });
  }
}

// Fetch nearby events saga
function* fetchNearbyEvents({ payload }) {
  try {
    const { accessToken, ...params } = payload;
    const response = yield call(getEventsNearbyByUser, params, accessToken);

    yield put(getNearbyEventsSuccess(response, params.userId));
  } catch (error) {
    yield put(getNearbyEventsFail(error));
    toast.error('Failed to fetch nearby events.', { autoClose: 2000 });
  }
}

// Select Thomas user
function* selectThomasUserSaga(action) {
  try {
    // If you already have the user data, you might skip the API call
    // Here, assuming you fetch fresh data by user ID
    const user = action.payload; // Or fetch using user ID

    // const user = yield call(fetchUserById, action.payload.id);
    yield put({ type: SELECT_THOMAS_USER_SUCCESS, payload: user });
  } catch (error) {
    yield put({ type: SELECT_THOMAS_USER_FAIL, payload: error.message });
    toast.error("Failed to select user.", { autoClose: 2000 });
  }
}

// Root saga
function* contactsSaga() {
  yield takeEvery(GET_THOMAS_USERS, fetchThomasUsers);
  yield takeEvery(GET_THOMAS_USER_PROFILE, fetchThomasUserProfile);
  yield takeEvery(ADD_NEW_THOMAS_USER, onAddNewThomasUser);
  yield takeEvery(UPDATE_THOMAS_USER, onUpdateThomasUser);
  yield takeEvery(DELETE_THOMAS_USER, onDeleteThomasUser);
  yield takeEvery(GET_USER_EVENTS, fetchThomasUserEvents);
  yield takeEvery(ADD_USER_EVENT, onAddUserEvent);
  yield takeEvery(UPDATE_USER_EVENT, onUpdateUserEvent);
  yield takeEvery(DELETE_USER_EVENT, onDeleteUserEvent);
  yield takeEvery(SELECT_THOMAS_USER, selectThomasUserSaga);
  yield takeEvery(ACCEPT_USER_INTO_EVENT, onAcceptUserIntoEvent);
  yield takeEvery(GET_NEARBY_EVENTS, fetchNearbyEvents);
}

export default contactsSaga;
