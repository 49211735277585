/**
 * Decide whether to create a new event based on user data and existing events.
 * @param {Object} user - The user data.
 * @param {Array} userEvents - The user's current events.
 * @param {number} probability - Base probability (default: 0.20 for 20%).
 * @returns {boolean}
 */
export const shouldCreateEvent = (user, userEvents, probability = 0.20) => {
  // Example logic: Only decide to create if user has no active events
  const hasNoActiveEvent = !userEvents.some(event => event.status === 'active');
  if (!hasNoActiveEvent) return false;
  return Math.random() < probability;
};

/**
 * Decide whether to ask to join an event based on user data and nearby events.
 * @param {Object} user - The user data.
 * @param {Array} nearbyEvents - Events nearby the user.
 * @param {number} probability - Base probability (default: 0.20 for 20%).
 * @returns {boolean}
 */
export const shouldAskToJoinEvent = (user, nearbyEvents, probability = 0.20) => {
  // Example logic: Only decide to join if there are nearby events
  if (nearbyEvents.length === 0) return false;
  // Additional conditions can be added here
  return Math.random() < probability;
};

/**
 * Decide whether to reply in chat based on user data and chat context.
 * @param {Object} user - The user data.
 * @param {Object} chat - The chat data.
 * @param {number} probability - Base probability (default: 0.75 for 75%).
 * @returns {boolean}
 */
export const shouldReplyInChat = (user, chat, probability = 0.75) => {
  // Example logic: Only reply if the chat is active and the user has interacted recently
  const isChatActive = chat.status === 'active';
  if (!isChatActive) return false;
  // Additional conditions can be added here
  return Math.random() < probability;
};

/**
 * Decide whether to accept a user into an event.
 * @param {Object} event - The event data.
 * @param {Object} user - The event owner.
 * @param {string} pendingUserId - The ID of the user requesting to join.
 * @returns {boolean}
 */
export const shouldAcceptUserIntoEvent = (event, user, pendingUserId) => {
  // Implement your logic here. For example:

  // Check if the event is not full
  const isEventFull = event.users.active.length >= event.maxParticipants;
  if (isEventFull) return false;

  // For now, accept the user
  return true;
};