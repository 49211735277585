import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Badge,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Alert,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteThomasUser,
  getUserEvents,
  deleteUserEvent
} from "../../store/thomas/actions";
import NewEventForm from "./NewEventForm";
import { toast } from "react-toastify";
import EditThomasUser from "./EditThomasUser";
import ThomasUserEvents from './ThomasUserEvents';
import ImageGalleryModal from "../../components/Common/ImageGalleryModal";
import EventsList from "../../components/Common/EventsList";

const calculateAge = (dateOfBirth) => {
  if (!dateOfBirth) return "N/A";
  const birthDate = new Date(dateOfBirth);
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const ThomasUserDetail = ({ user: userProfile, nearbyEvents }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const { usersEvents } = useSelector((state) => state.thomas);
  const events = usersEvents[userProfile._id] || [];
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [showNewEventForm, setShowNewEventForm] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // State for Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleImageClick = (imageSrc, imgIndex) => {
    setSelectedImage(imageSrc);
    setCurrentImageIndex(imgIndex);
    toggleModal();
  };

  const handleDeleteEvent = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        dispatch(deleteUserEvent(eventId, userProfile));
        toast.success("Event deleted successfully", { autoClose: 2000 });
      } catch (err) {
        console.log("handleDeleteEventhandleDeleteEvent:", err);

        toast.error("Failed to delete event", { autoClose: 2000 });
      }
    }
  };

  const fetchEvents = async () => {
    if (userProfile?._id) {
      try {
        dispatch(getUserEvents(userProfile._id));
        setLoadingEvents(false);
      } catch (err) {
        setLoadingEvents(false);
        setError("Failed to fetch events");
        toast.error("Failed to fetch events", { autoClose: 2000 });
      }
    } else {
      setLoadingEvents(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [userProfile]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleToggleUpdateUser = () => {
    setIsEditingUser(!isEditingUser);
  };

  const handleRemoveUser = () => {
    if (window.confirm("Are you sure you want to remove this user?")) {
      setLoadingUser(true);
      try {
        dispatch(deleteThomasUser(userProfile._id));
        setLoadingUser(false);
      } catch (err) {
        setLoadingUser(false);
        setError("Failed to remove user");
      }
    }
  };

  const handleToggleAddEvent = () => {
    setShowNewEventForm(!showNewEventForm);
  };

  if (!userProfile) {
    return <p>Select a user to view details.</p>;
  }

  return (
    <Card className="user-detail-card">
      <CardBody>
        {loadingUser && (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        )}
        {error && <Alert color="danger">{error}</Alert>}
        {!loadingUser && !error && (
          <>
            <div className="user-action-menu mb-3">
              <Button color="primary" onClick={handleToggleUpdateUser}>
                <i className={`bx ${isEditingUser ? "bx-x" : "bx-edit"}`}></i>
              </Button>
              <Button
                color="danger"
                onClick={handleRemoveUser}
                className="ms-2"
              >
                <i className="bx bx-trash"></i>
              </Button>
              <Button
                color="success"
                onClick={handleToggleAddEvent}
                className="ms-2"
              >
                <i className="bx bx-plus"></i>{" "}
                {showNewEventForm ? "Cancel Event" : "Add Event"}
              </Button>
            </div>

            {isEditingUser ? (
              <EditThomasUser
                user={userProfile}
                onCancel={handleToggleUpdateUser}
              />
            ) : (
              <>
                <CardTitle className="user-detail-title">
                  {userProfile?.profileImage && (
                    <img
                      src={userProfile.profileImage}
                      alt="Profile"
                      className="user-profile-image"
                    />
                  )}
                  {`${userProfile?.firstName || ""} ${
                    userProfile?.lastName || ""
                  } - Age: ${calculateAge(userProfile?.dateOfBirth)}`}
                </CardTitle>

                {!showNewEventForm && (
                  <>
                    <Nav tabs className="user-detail-tabs">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => toggleTab("1")}
                        >
                          Info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => toggleTab("2")}
                        >
                          Events
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => toggleTab("3")}
                        >
                          Nearby Events
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <ListGroup flush className="user-detail-list">
                          <ListGroupItem>
                            <strong>Name:</strong>{" "}
                            {`${userProfile.firstName || ""} ${
                              userProfile.lastName || ""
                            }`}
                          </ListGroupItem>
                          <ListGroupItem>
                            <strong>Email:</strong> {userProfile.email || "N/A"}
                          </ListGroupItem>
                          <ListGroupItem>
                            <strong>Date of Birth:</strong>{" "}
                            {userProfile.dateOfBirth || "N/A"}
                          </ListGroupItem>
                          <ListGroupItem>
                            <strong>Location:</strong>{" "}
                            {`${userProfile.currentCity || "N/A"}, ${
                              userProfile.currentCountry || "N/A"
                            }`}
                          </ListGroupItem>
                          <ListGroupItem>
                            <strong>Born:</strong>{" "}
                            {`${userProfile.bornCity || "N/A"}, ${
                              userProfile.bornCountry || "N/A"
                            }`}
                          </ListGroupItem>
                          <ListGroupItem>
                            <strong>Joined:</strong>{" "}
                            {userProfile.createdAtISODate
                              ? new Date(
                                  userProfile.createdAtISODate
                                ).toLocaleDateString()
                              : "N/A"}
                          </ListGroupItem>
                          <ListGroupItem>
                            <strong>Languages:</strong>{" "}
                            {userProfile.languages?.length > 0
                              ? userProfile.languages.join(", ")
                              : "N/A"}
                          </ListGroupItem>
                          <ListGroupItem>
                            <strong>Interests:</strong>{" "}
                            {userProfile.interests &&
                            userProfile.interests.length > 0 ? (
                              userProfile.interests.map((interest, idx) => (
                                <Badge
                                  key={idx}
                                  className="interest-badge me-1 mb-1"
                                  color="info"
                                >
                                  {interest}
                                </Badge>
                              ))
                            ) : (
                              "N/A"
                            )}
                          </ListGroupItem>
                          <ListGroupItem>
                            <strong>Description:</strong>{" "}
                            {userProfile.description || "N/A"}
                          </ListGroupItem>
                          {/* User Pictures */}
                          <ListGroupItem>
                            <strong>Pictures:</strong>
                            {userProfile.pictures &&
                            userProfile.pictures.length > 0 ? (
                              <div className="pictures-gallery mt-2">
                                {userProfile.pictures.map((picture, idx) => (
                                  <div
                                    key={idx}
                                    className="picture-thumbnail"
                                    onClick={() =>
                                      handleImageClick(picture.src, idx)
                                    }
                                  >
                                    <img
                                      src={picture.src}
                                      alt={`User Picture ${idx + 1}`}
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <p>No pictures available.</p>
                            )}
                          </ListGroupItem>
                        </ListGroup>
                      </TabPane>

                      <TabPane tabId="2">
                        {loadingEvents ? (
                          <div className="text-center">
                            <Spinner color="primary" />
                          </div>
                        ) : (
                          <ThomasUserEvents
                            events={events}
                            userId={userProfile._id}
                            onSelectEvent={handleSelectEvent} />
                        )}
                      </TabPane>
                      <TabPane tabId="3">
                        <h5 className="mt-3">Nearby Events</h5>
                        <EventsList
                          events={nearbyEvents}
                          userId={userProfile._id}
                          currentUser={userProfile}
                          isOwner={false} // The user does not own these events
                          onSelectEvent={handleSelectEvent}
                          handleDeleteEvent={handleDeleteEvent}
                        />
                      </TabPane>
                    </TabContent>
                  </>
                )}

                {/* Show NewEventForm when adding a new event */}
                {showNewEventForm && (
                  <NewEventForm
                    onSave={handleToggleAddEvent}
                    user={userProfile}
                  />
                )}

                {/* Modal for Image Preview */}
                <ImageGalleryModal
                  isOpen={modalOpen}
                  toggle={toggleModal}
                  images={userProfile.pictures}
                  currentIndex={currentImageIndex}
                  setCurrentIndex={setCurrentImageIndex}
                />
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ThomasUserDetail;
