import * as url from "./url_helper";
import { get, post, put, del, axiosAPPApi, axiosApi } from './api_helper';
import { createApi } from 'unsplash-js';
import { unsplash as unsplashKey } from '../config';
import { getValidAccessToken } from "./accessTokenHelper.js"; // Import the helper

const unsplash = createApi({
  accessKey: unsplashKey,
});

export const getLanguages = () => get('https://vb94l8xk52.execute-api.eu-west-3.amazonaws.com/api/languages');
export const getInterests = () => get('https://vb94l8xk52.execute-api.eu-west-3.amazonaws.com/api/interests');
export const getCountries = () => get('https://vb94l8xk52.execute-api.eu-west-3.amazonaws.com/api/countries');

export const fetchSocialImg = async (query = "friends") => {
  let link = null;
  let imgData = {};

  try {
    const result = await unsplash.photos.getRandom({ query: query, count: 1 });
    const { description, location, urls, user, links } = result.response[0];
    link = urls.regular;
    imgData = {
      description: description || '',
      location: location?.name || '',
      authorName: user?.name,
      authorLink: user?.links.html,
      unsplashLink: links.html
    };

    if (link) {
      link = `${link}&fit=crop&crop=edges`;
    }
  } catch (error) {
    console.log("something went wrong with fetching Unsplash image!", error);
  }

  return {
    bgImgLink: link,
    bgImgData: imgData
  };
};

/**
 * Adds a new Thomas user with access token handling
 * @param {Object} userData - The user data to add
 * @param {Object} user - The user object containing accessToken, lastTokenUpdate, uid
 */
export const addNewThomasUser = user => post(url.ADD_NEW_USER, user);

/**
 * Fetches a Thomas user profile by ID (no access token needed)
 * @param {string} userId - The ID of the user
 */
export const getUserProfile = (userId) => get(`${url.GET_USER_PROFILE}/${userId}`);
export const updateUser = user => put(`${url.UPDATE_USER}/${user._id}`, user);

export const deleteUser = async (userId) => {
  try {
    const response = await del(`/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

/**
 * Fetches events where the user is a participant (no access token needed)
 * @param {Object} payload - The payload containing userId
 * @param {string} status - The status of the event (default: "active")
 */
export const fetchUserEvents = (payload, status = "active") => {
  return get(`${url.GET_USER_EVENTS}?userId=${payload.userId}&userStatus=active&eventStatus=${status}`);
};

/**
 * Fetches events where the user is a participant with access token handling
 * @param {Object} payload - The payload containing userId
 * @param {Object} user - The user object containing accessToken, lastTokenUpdate, uid
 * @param {string} status - The status of the event (default: "active")
 */
export const fetchThomasUserEventsAPI = async (user, status = "active") => {
  try {
    // const accessToken = await getValidAccessToken(user);
    const response = await fetchUserEvents({ userId: user._id }, status);

    // const response = await axiosAPPApi.get(`/user_events?userId=${user._id}&userStatus=active&eventStatus=${status}`, {
    //   headers: { "sc-uxr-jtkew": accessToken },
    // });

    if (!response) {
      return [];
    }
    return response?.data || [];
  } catch (error) {
    if (error.message === 'TokenExpired') {
      throw new Error('TokenExpired');
    }
    console.error("Error fetching Thomas user events API:", error);
    return [];
  }
};

/**
 * Creates a user event with access token handling
 * @param {Object} data - The event data
 * @param {Object} user - The user object containing accessToken, lastTokenUpdate, uid
 */
export const createUserEvent = async (data, user) => { // Pass user object
  try {
    console.log('createUserEvent', data, user);
    const accessToken = await getValidAccessToken(user);

    const response = await axiosAPPApi.post("/events", {
      ...data,
      userId: data.createdBy.user._id
    }, {
      headers: { "sc-uxr-jtkew": accessToken },
    });
    return response.data;
  } catch (error) {
    if (error.message === 'TokenExpired') {
      throw new Error('TokenExpired');
    }
    console.error("Error creating user event:", error);
    throw error;
  }
};

/**
 * Asks to join an event with access token handling
 * @param {string} eventId - The ID of the event
 * @param {string} userId - The ID of the user requesting to join
 * @param {Object} user - The user object containing accessToken, lastTokenUpdate, uid
 */
export const askToJoinEvent = async (eventId, user) => { // Pass user object
  const data = { eventId };
  console.log('askToJoinEvent', data, user);

  try {
    const accessToken = await getValidAccessToken(user);

    const response = await axiosAPPApi.post("/event/join", {...data, userId: user._id}, {
      headers: { "sc-uxr-jtkew": accessToken },
    });
    return response.data;
  } catch (error) {
    if (error.message === 'TokenExpired') {
      throw new Error('TokenExpired');
    }
    console.error("Error asking to join event:", error);
    throw error.response ? error.response.data : error;
  }
};

/**
 * Accepts a user into an event with access token handling
 * @param {string} eventId - The ID of the event
 * @param {string} userId - The ID of the user to accept
 * @param {Object} user - The user object containing accessToken, lastTokenUpdate, uid
 */
export const acceptUserIntoEventApi = async (eventId, userId, user) => { // Pass user object
  const data = {
    eventId,
    senderId: userId,
  };
  console.log('acceptUserIntoEventApi', data, user);

  try {
    const accessToken = await getValidAccessToken(user);

    const response = await axiosAPPApi.post("/event/join/accept", data, {
      headers: { "sc-uxr-jtkew": accessToken },
    });
    return response.data;
  } catch (error) {
    if (error.message === 'TokenExpired') {
      throw new Error('TokenExpired');
    }
    console.error("Error accepting user into event:", error);
    throw error.response ? error.response.data : error;
  }
};

/**
 * Updates an event with access token handling
 * @param {Object} event - The event data to update
 * @param {Object} user - The user object containing accessToken, lastTokenUpdate, uid
 */
export const updateUserEvent = async (event, user) => { // Pass user object
  try {
    console.log('updateUserEvent', event, user);

    const accessToken = await getValidAccessToken(user);

    const response = await axiosAPPApi.put(`/events/${event.id}`, event, {
      headers: { "sc-uxr-jtkew": accessToken },
    });
    return response.data;
  } catch (error) {
    if (error.message === 'TokenExpired') {
      throw new Error('TokenExpired');
    }
    console.error("Error updating user event:", error);
    throw error;
  }
};

/**
 * Deletes an event with access token handling
 * @param {string} eventId - The ID of the event to delete
 * @param {Object} user - The user object containing accessToken, lastTokenUpdate, uid
 */
export const deleteUserEvent = async (eventId, user) => { // Pass user object
  try {
    console.log('deleteUserEvent', eventId, user);
    // const accessToken = await getValidAccessToken(user);

    const response = await axiosAPPApi.delete(`/events/${eventId}`, {
      headers: { "sc-uxr-jtkew": user.accessToken },
    });
    return response.data;
  } catch (error) {
    if (error.message === 'TokenExpired') {
      throw new Error('TokenExpired');
    }
    console.error("Error deleting user event:", error);
    throw error;
  }
};

/**
 * Fetches nearby events based on user location with access token handling
 * @param {Object} params - Parameters including latitude, longitude, radius, and userId
 * @param {Object} user - The user object containing accessToken, lastTokenUpdate, uid
 */
export const getEventsNearbyByUser = async (params, user) => { // Pass user object
  const { latitude, longitude, radius, userId, accessToken } = params;
  const queryParams = new URLSearchParams({
    lat: latitude,
    lon: longitude,
    radius,
    userId,
  }).toString();

  try {
    // const accessToken = await getValidAccessToken(user);

    const response = await axiosAPPApi.get(`/events/nearby?${queryParams}`, {
      headers: { "sc-uxr-jtkew": accessToken },
    });

    return response.data.results;
  } catch (error) {
    if (error.message === 'TokenExpired') {
      throw new Error('TokenExpired');
    }
    console.error('Error fetching nearby events:', error);
    throw error;
  }
};
