import React from "react";
import { IMAGE_BASE_URL } from "../../config";

const ThomasUsers = ({ users, selectedUserId, onUserSelect }) => {
  return (
    <div className="user-list">
      {users.map((user) => {
        return (
          <div
            key={user._id}
            className={`user-card ${
              selectedUserId === user._id ? "selected" : ""
            }`}
            onClick={() => onUserSelect(user._id)}
          >
            <div className="user-card-content">
              <div className="user-card-header">
                <img
                  src={
                    user.profileImage &&
                    user.profileImage.includes("firebase")
                      ? user.profileImage
                      : user.profileImage
                      ? `${IMAGE_BASE_URL}/${user.profileImage}`
                      : "/default-profile.png"
                  }
                  alt={`${user.firstName} ${user.lastName}`}
                  className="user-avatar"
                />
                <div className="user-details">
                  <h5>{`${user.firstName} ${user.lastName}`}</h5>
                  <p>{`${user.currentCity || "-"}, ${
                    user.currentCountry || "-"
                  }`}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ThomasUsers;
