import React from 'react';
import { Modal } from 'reactstrap';

const ImageGalleryModal = ({
  isOpen,
  toggle,
  images,
  currentIndex,
  setCurrentIndex,
}) => {
  const goToPreviousImage = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
  };

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="custom-modal"
      backdropClassName="custom-modal-backdrop"
      modalClassName="custom-modal-dialog"
    >
      <button
        className="close-button"
        onClick={toggle}
        aria-label="Close"
      >
        &times;
      </button>

      {/* Navigation Buttons */}
      {images && images.length > 1 && (
        <>
          {currentIndex > 0 && (
            <button
              className="nav-button left"
              onClick={goToPreviousImage}
              aria-label="Previous Image"
            >
              &#10094;
            </button>
          )}
          {currentIndex < images.length - 1 && (
            <button
              className="nav-button right"
              onClick={goToNextImage}
              aria-label="Next Image"
            >
              &#10095;
            </button>
          )}
        </>
      )}

      {/* Image */}
      <img
        src={images[currentIndex]?.src}
        alt={`Image ${currentIndex + 1}`}
        className="full-image"
      />
  </Modal>
  );
};

export default ImageGalleryModal;
