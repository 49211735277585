import { get, post, put, del } from './api_helper';
import * as url from "./url_helper";

export const getEvents = ({filters, pageSize, pageIndex, sortBy}) => {
  const params = new URLSearchParams();

  if (filters && Object.keys(filters).length > 0) {
    Object.entries(filters)?.forEach(([key, value]) => {
      if (value !== undefined) {
        params.append(key, value);
      }
    });
  }

  if (pageSize !== undefined && pageSize > 100) params.append('pageSize', pageSize);
  if (pageIndex !== undefined && pageIndex > 0) params.append('pageIndex', pageIndex);
  if (!!sortBy) params.append('sortBy', JSON.stringify(sortBy));

  const query = params.toString();

  return get(`${url.GET_EVENTS}${query ? `?${query}` : ''}`);
};

export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);
export const updateEvent = event => put(`${url.UPDATE_EVENT}/${event._id}`, event);
export const deleteEvent = eventId => del(`${url.DELETE_EVENT}/${eventId}`);

export const getEventById = eventId => get(`${url.GET_EVENT_BY_ID}/${eventId}`);

