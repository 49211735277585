import OpenAIApi from "openai";
import { chatGPTModel, chatGPTReamikAPIKey } from "../../../config";
import { attemptToParseJSON } from '../../../utils/parseJSON';

const openai = new OpenAIApi({
  apiKey: chatGPTReamikAPIKey,
  dangerouslyAllowBrowser: true, // Enable browser usage
  // TODO: move this to backend
});

export async function chatGPTAPI(prompt, userId, json = true) {
  try {
    const { messages, temperature = 0.95, top_p = 0.9 } = Array.isArray(prompt)
      ? { messages: prompt }
      : prompt;

    console.log("chatGPTAPI request", messages, userId, json);

    const completion = await openai.chat.completions.create({
      model: chatGPTModel,
      messages,
      temperature,
      top_p,
    });

    let response = completion?.choices[0]?.message?.content;
    console.log("chatGPTAPI response:", completion, completion?.choices, response);

    if (json && typeof response === "string") {
      try {
        // try to parse json
        response = attemptToParseJSON(response);
      } catch (error) {
        console.error("Error parsing JSON response:", error);
        return null;
      }
    }

    return response;
  } catch (error) {
    console.error(`Error generating text: ${error}`);
    return null;
  }
}
