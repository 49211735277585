export const languageMap = (lang) => {
  switch (lang) {
    case 'en':
      return 'English';
    case 'de':
      return 'German';
    case 'fr':
      return 'French';
    case 'es':
      return 'Spanish';
    case 'it':
      return 'Italian';
    case 'nl':
      return 'Dutch';
    case 'pt':
      return 'Portuguese';
    case 'ru':
      return 'Russian';
    case 'zh':
      return 'Chinese';
    case 'ja':
      return 'Japanese';
    case 'ko':
      return 'Korean';
    case 'ar':
      return 'Arabic';
    case 'tr':
      return 'Turkish';
    case 'pl':
      return 'Polish';
    case 'cs':
      return 'Czech';
    case 'sv':
      return 'Swedish';
    case 'da':
      return 'Danish';
    case 'fi':
      return 'Finnish';
    case 'no':
      return 'Norwegian';
    case 'hu':
      return 'Hungarian';
    case 'el':
      return 'Greek';
    case 'he':
      return 'Hebrew';
    case 'id':
      return 'Indonesian';
    case 'ms':
      return 'Malay';
    case 'th':
      return 'Thai';
    case 'vi':
      return 'Vietnamese';
    case 'hi':
      return 'Hindi';
    case 'bn':
      return 'Bengali';
    case 'tl':
      return 'Filipino';
    case 'sw':
      return 'Swahili';
    case 'af':
      return 'Afrikaans';
    case 'sq':
      return 'Albanian';
    case 'am':
      return 'Amharic';
    case 'hy':
      return 'Armenian';
    case 'az':
      return 'Azerbaijani';
    case 'eu':
      return 'Basque';
    case 'be':
      return 'Belarusian';
    case 'bs':
      return 'Bosnian';
    case 'bg':
      return 'Bulgarian';
    case 'ca':
      return 'Catalan';
    case 'ceb':
      return 'Cebuano';
    case 'ny':
      return 'Chichewa';
    case 'hr':
      return 'Croatian';

    default:
      return 'English';
  }
}