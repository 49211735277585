import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Button,
  Spinner
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getInterests, getLanguages } from '../../helpers/thomas_helper';
import GooglePlaces from '../../components/Common/GooglePlaces';
import { interactWithAssistantUserData } from '../../helpers/chat/chatGPT/interactWithAssistant';
import { thomasPrompt_newUser } from '../../helpers/chatGPT/thomasPrompt_newUser';
import { ObjectId } from 'bson';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import ImageUploader from '../../components/Common/ImageUploader';
import { toast } from 'react-toastify';
import { assistant_create_user, googleMapsAPIKey } from '../../config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../helpers/firebaseInit';
import { useSelector } from 'react-redux';
import { updateThomasUser } from '../../store/thomas/actions';

Modal.setAppElement('#root');

const NewUserForm = ({ onSave, toggleForm }) => {
  const dispatch = useDispatch();
  const { users } = useSelector(state => state.thomas);
  const [interestsList, setInterestsList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [loadingGPT, setLoadingGPT] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [loadingInterests, setLoadingInterests] = useState(false);
  const [loadingLanguages, setLoadingLanguages] = useState(false);
  const [userId, setUserId] = useState(null);
  const [accessToken, setAccessToken] = useState(null); // Store accessToken
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [loadingNewUser, setLoadingNewUser] = useState(false);
  const [email, setEmail] = useState(null);
  const [createdUser, setCreatedUser] = useState(null);

  useEffect(() => {
    console.log('Users:', users);
    console.log('Email:', email);


    if (users) {
      // find the new user, if exist setLoadingNewUser to false
      const newUser = users.find(user => user.email === email);
      if (newUser) {
        setCreatedUser(newUser);
        setLoadingNewUser(false);
      }
    }
  }, [users])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingInterests(true);
        setLoadingLanguages(true);
        const interests = await getInterests();
        const languages = await getLanguages();
        setInterestsList(interests?.results || []);
        setLanguagesList(languages || []);
        setLoadingInterests(false);
        setLoadingLanguages(false);
      } catch (error) {
        console.error('Error fetching interests/languages:', error);
        setLoadingInterests(false);
        setLoadingLanguages(false);
        toast.error('Failed to load interests or languages.', { autoClose: 3000 });
      }
    };
    fetchData();
  }, []);

  const handleCompleteWithChatGPT = async () => {
    setLoadingGPT(true);
    console.log("handleCompleteWithChatGPT");

    const promptParameters = thomasPrompt_newUser(
      validation.values.lang || "English",
      validation.values.currentLocation?.name || "Barcelona"
    );
    console.log("Prompt Parameters:", promptParameters);

    try {
      const messages = [
        { role: "user", content: promptParameters[0].text } // Send parameters as a string
      ];
      const chatGPTResponse = await interactWithAssistantUserData(messages);
      setLoadingGPT(false);

      if (chatGPTResponse) {
        let content = chatGPTResponse;
        validation.setFieldValue('bornCity', content.bornLocation.name || "");
        validation.setFieldValue('bornCountry', content.bornLocation.country || "");
        validation.setFieldValue('currentCity', content.currentLocation.name || "");
        validation.setFieldValue('currentCountry', content.currentLocation.country || "");

        if (content) {
          try {
            // Assuming the assistant returns a JSON object
            validation.setValues({
              firstName: content.firstName || "",
              lastName: content.lastName || "",
              enabled: content.enabled || false,
              gender: content.gender || "",
              email: content.email || "",
              description: content.description || "",
              bornCity: content.bornLocation.name || "",
              bornCountry: content.bornLocation.country || "",
              currentCity: content.currentLocation.name || "",
              currentCountry: content.currentLocation.country || "",
              location: {
                name: content.location?.name || "",
                coordinates: content.location?.coordinates || []
              },
              interests: content.interests || [],
              languages: content.languages || [],
              pictures: content.pictures || [],
              dateOfBirth: content.dateOfBirth || "",
              lang: content.lang || ""
            });

            // Handle uploaded images if provided
            if (content.pictures && Array.isArray(content.pictures)) {
              const uploaded = content.pictures.map(url => ({
                _id: new ObjectId().toString(),
                src: url,
                title: 'Generated Picture',
                createdAt: Date.now(),
                uploading: false,
              }));
              setUploadedImages(uploaded);
            }

          } catch (error) {
            console.error("Error setting form values:", error.message);
            toast.error("Failed to set form values from assistant response.", { autoClose: 3000 });
          }
        } else {
          console.error("Content is undefined or empty");
          toast.error("Assistant failed to generate a response.", { autoClose: 3000 });
        }
      } else {
        console.error("Invalid ChatGPT response format", chatGPTResponse);
        toast.error("Received an invalid response from the assistant.", { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error interacting with assistant:", error);
      setLoadingGPT(false);
      toast.error("An error occurred while interacting with the assistant.", { autoClose: 3000 });
    }
  };

  const handleImageUploadSuccess = (images) => {
    setUploadedImages(images);
    toast.success('Images uploaded successfully!', { autoClose: 3000 });
  };

  const handleImageUploadFailure = (error) => {
    console.error("Image upload failed:", error);
    toast.error('Image upload failed.', { autoClose: 3000 });
  };

  const handleImageUploaderClose = () => {
    if (!createdUser) return;
    // update the images to the user profile, so
    dispatch(updateThomasUser({
      ...createdUser,
      profileImages: uploadedImages.map(image => image.src),
      profileImage: uploadedImages[0]?.src,
      pictures: uploadedImages.map((image) => ({
        _id: image._id || new ObjectId().toString(),
        createdAt: image.createdAt || Date.now(),
        title: image.title || 'User uploaded picture',
        src: image.src || '',
      })),
    }));
    setShowImageUploader(false);
    setUploadedImages([]);
    if (toggleForm) toggleForm();
  };

  const onFormSubmit = async (values) => {
    const { email, location, ...props } = values;

    try {
      const password = Math.random().toString(36).slice(-8);
      // 1. Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const firebaseUser = userCredential.user;
      const uid = firebaseUser.uid;
      setUserId(uid);
      setEmail(email);
      // 2. Get the accessToken (ID token)
      const token = await firebaseUser.getIdToken();

      // Get the refresh token
      const refreshToken = await firebaseUser.stsTokenManager.refreshToken;      ;
      console.log('refreshToken', refreshToken, token, firebaseUser);

      setAccessToken(token);

      // 3. Create user data to send to backend
      const newUser = {
        ...props,
        email,
        uid,
        createdAt: Date.now(),
        createdAtISODate: new Date().toISOString(),
        updatedAt: Date.now(),
        updatedAtISODate: new Date().toISOString(),
        isThomas: true,
        isActive: false,
        isActiveExpirationDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        pictures: [], // Initially empty; images will be uploaded separately
        blockedUsers: [],
        blockedBy: [],
        profileImage: "",
        profileImages: [],
        following: [],
        followedBy: [],
        notifications: [],
        loggedIn: false,
        participatingEventsLast24Hours: 0,
        subscription: "free",
        pushTokens: [],
        sendNotificationOnNewNearEvent: false,
        lastNotificationReceived: "",
        onboardingCompleted: true,
        location: {
          type: "Point",
          coordinates: location?.coordinates || [],
        },
        accessToken: token,
        lastTokenUpdate: Date.now(),
        password,
        refreshToken,
      };

      // 4. Save user data to backend
      // Assuming you have an API endpoint to create a user
      // const response = await fetch('/api/users', { // Adjust the API endpoint as needed
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${token}`, // Include the token if required by backend
      //   },
      //   body: JSON.stringify(newUser)
      // });

      // if (!response.ok) {
      //   throw new Error('Failed to save user data to backend.');
      // }

      // const savedUser = await response.json();
      // console.log("User saved to backend:", savedUser);
      if (onSave) onSave(newUser);
      setLoadingNewUser(true);
      // 5. Show Image Uploader
      setShowImageUploader(true);
      toast.success('User created successfully! Please upload your images.', { autoClose: 3000 });

      // 6. Reset form
      validation.resetForm();
    } catch (error) {
      console.error("Error adding new user:", error);
      toast.error('Failed to create user.', { autoClose: 3000 });
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      enabled: true,
      gender: "",
      email: "",
      description: "",
      pictures: [],
      interests: [],
      languages: [],
      dateOfBirth: "",
      lang: "",
      location: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      email: Yup.string()
        .matches(
          /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Please Enter Valid Email"
        )
        .required("Please Enter Your Email"),
      dateOfBirth: Yup.string().required("Please Enter Your Date of Birth"),
      // Add other validations as needed
    }),
    onSubmit: onFormSubmit,
  });

  const handleLocationChange = (field, selectedPlace) => {
    console.log('Selected Place:', field, selectedPlace);

    if (selectedPlace && selectedPlace.value && selectedPlace.value.geometry) {
      console.log('Selected Place:', selectedPlace);

      const lat = selectedPlace.value.geometry.location.lat();
      const lng = selectedPlace.value.geometry.location.lng();
      const coordinates = [lng, lat]; // MongoDB expects [lng, lat]
      validation.setFieldValue(field, {
        name: selectedPlace.label,
        coordinates,
      });
    } else if (selectedPlace && selectedPlace.label) {
      console.log('Selected Place:', selectedPlace);

      const cityAndCountry = selectedPlace.label;
      let city = '';
      let country = '';

      if (selectedPlace.value?.structured_formatting?.main_text) {
        city = selectedPlace.value.structured_formatting.main_text;
      } else if (cityAndCountry.includes(',')) {
        const parts = cityAndCountry.split(',');
        city = parts[0].trim();
        country = parts[1].trim();
      } else {
        city = cityAndCountry;
      }

      // Fetch coordinates based on cityAndCountry
      const getCoordinates = async () => {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(cityAndCountry)}&key=${googleMapsAPIKey}`
          );
          console.log('Response:', response);

          const data = await response.json();
          if (data.results && data.results.length > 0) {
            const location = data.results[0].geometry.location;
            const coordinates = [location.lng, location.lat];
            validation.setFieldValue(field, { name: city, coordinates });
          }
        } catch (error) {
          console.error('Error fetching coordinates:', error);
        }
      };

      getCoordinates();
    } else {
      validation.setFieldValue(field, { name: '', coordinates: [] });
    }
  };

  const handleCityCountryChange = (field, selectedPlace) => {
    const cityFieldName = field === 'bornLocation' ? 'bornCity' : 'currentCity';
    const countryFieldName = field === 'bornLocation' ? 'bornCountry' : 'currentCountry';
    let valueCity = '';
    let valueCountry = '';

    if (selectedPlace && selectedPlace.value && selectedPlace.value.address_components) {
      valueCity = selectedPlace.value.address_components.find(
        (component) => component.types.includes('locality') || component.types.includes('administrative_area_level_1')
      )?.long_name || '';
      valueCountry = selectedPlace.value.address_components.find(
        (component) => component.types.includes('country')
      )?.long_name || '';
    }

    validation.setFieldValue(cityFieldName, valueCity);
    validation.setFieldValue(countryFieldName, valueCountry);
  };

  return (
    <Form
      id="thomasForm"
      onSubmit={e => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >

      {loadingNewUser ? (
        <Row>
          <Col xs={12}>
            <Spinner size="sm" />
          </Col>
        </Row>
      ) : !showImageUploader ? (
        <>

        <Row>
        <Col xs={12} md={4}>
          {/* First Name */}
          <div className="mb-3 mt-3">
            <Label className="form-label">First Name</Label>
            <Input
              name="firstName"
              type="text"
              placeholder="Insert First Name"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.firstName || ""}
              invalid={
                validation.touched.firstName && validation.errors.firstName
                  ? true
                  : false
              }
            />
            {validation.touched.firstName && validation.errors.firstName ? (
              <FormFeedback type="invalid">
                {validation.errors.firstName}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Last Name */}
          <div className="mb-3 mt-3">
            <Label className="form-label">Last Name</Label>
            <Input
              name="lastName"
              type="text"
              placeholder="Insert Last Name"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lastName || ""}
              invalid={
                validation.touched.lastName && validation.errors.lastName
                  ? true
                  : false
              }
            />
            {validation.touched.lastName && validation.errors.lastName ? (
              <FormFeedback type="invalid">
                {validation.errors.lastName}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Email */}
          <div className="mb-3 mt-3">
            <Label className="form-label">Email</Label>
            <Input
              name="email"
              type="email"
              placeholder="Insert Email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          {/* Gender */}
          <div className="mb-3">
            <Label className="form-label">Gender</Label>
            <Input
              name="gender"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.gender || ""}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </Input>
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Date of Birth */}
          <div className="mb-3">
            <Label className="form-label">Date of Birth</Label>
            <Input
              name="dateOfBirth"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.dateOfBirth || ""}
              invalid={
                validation.touched.dateOfBirth && validation.errors.dateOfBirth
                  ? true
                  : false
              }
            />
            {validation.touched.dateOfBirth && validation.errors.dateOfBirth ? (
              <FormFeedback type="invalid">
                {validation.errors.dateOfBirth}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Enabled */}
          <div className="mb-3">
            <Label className="form-label">Enabled</Label>
            <div className="border p-2 rounded d-flex align-items-center">
              <Input
                name="enabled"
                type="checkbox"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.enabled || false}
                className="me-2"
              />
              <span>Enabled</span>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          {/* Born Location */}
          <div className="mb-3">
            <Label className="form-label">Born Location</Label>
            <GooglePlaces
              onPress={value => handleCityCountryChange('bornLocation', value)}
              placeholder="Enter birth city or country"
              initialValue={
                validation.values.bornCity
                  ? `${validation.values.bornCity}, ${validation.values.bornCountry}`
                  : ""
              }
            />
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Current Location */}
          <div className="mb-3">
            <Label className="form-label">Current Location</Label>
            <GooglePlaces
              onPress={value => handleCityCountryChange('currentLocation', value)}
              placeholder="Enter current city or country"
              initialValue={
                validation.values.currentCity
                  ? `${validation.values.currentCity}, ${validation.values.currentCountry}`
                  : ""
              }
            />
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Location */}
          <div className="mb-3">
            <Label className="form-label">Location</Label>
            <GooglePlaces
              onPress={value => handleLocationChange('location', value)}
              placeholder="Enter location"
              initialValue={validation.values.location?.name || ""}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          {/* Description */}
          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <Input
              name="description"
              type="textarea"
              placeholder="Insert Description"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
            />
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Interests */}
          <div className="mb-3">
            <Label className="form-label">Interests</Label>
            <Input
              name="interests"
              type="select"
              multiple
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.interests || []}
            >
              {interestsList?.map(interest => (
                <option key={interest.id} value={interest.id}>
                  {String.fromCodePoint(parseInt(interest.emoji, 16))} {interest.id}
                </option>
              ))}
            </Input>
            {validation.touched.interests && validation.errors.interests && (
              <FormFeedback>{validation.errors.interests}</FormFeedback>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Languages */}
          <div className="mb-3">
            <Label className="form-label">Languages</Label>
            <Input
              name="languages"
              type="select"
              multiple
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.languages || []}
            >
              {languagesList?.map(language => (
                <option key={language.id} value={language.id}>
                  {language.name}
                </option>
              ))}
            </Input>
            {validation.touched.languages && validation.errors.languages && (
              <FormFeedback>{validation.errors.languages}</FormFeedback>
            )}
          </div>
        </Col>
      </Row>
        <Row>
          <Col xs={12}>
            <div className="text-end">
              <Button
                type="button"
                color="primary"
                disabled={loadingGPT}
                onClick={handleCompleteWithChatGPT}
              >
                {loadingGPT ? <Spinner size="sm" /> : 'Complete with ChatGPT'}
              </Button>
              <Button
                type="submit"
                color="success"
                className="ms-2"
                disabled={loadingGPT}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
        </>
      ) : showImageUploader && (
        <>
          <Row className="mt-4">
            <Col xs={12}>
              <h5>Upload Your Images</h5>
              <ImageUploader
                userId={userId} // Passing the real user ID
                uploadedImages={uploadedImages}
                setUploadedImages={setUploadedImages}
                onUploadSuccess={handleImageUploadSuccess}
                onUploadFailure={handleImageUploadFailure}
                maxFiles={10} // Adjust as needed
                accept="image/*"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="text-end mt-2">
                <Button
                  type="button"
                  color="secondary"
                  onClick={handleImageUploaderClose}
                >
                  Save Images
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}

      {/* Image Modal */}
      <Modal
        isOpen={selectedImageIndex !== null}
        onRequestClose={() => setSelectedImageIndex(null)}
        contentLabel="Image Modal"
        className="image-modal"
        overlayClassName="image-modal-overlay"
      >
        <div className="image-modal-content">
          {selectedImageIndex !== null && (
            <>
              <button onClick={() => setSelectedImageIndex(null)} className="close-button">Close</button>
              <img src={uploadedImages[selectedImageIndex].src} alt={uploadedImages[selectedImageIndex].title} className="modal-image" />
              <div className="navigation-buttons">
                <button
                  onClick={() => setSelectedImageIndex((selectedImageIndex - 1 + uploadedImages.length) % uploadedImages.length)}
                  disabled={uploadedImages.length <= 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => setSelectedImageIndex((selectedImageIndex + 1) % uploadedImages.length)}
                  disabled={uploadedImages.length <= 1}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </Form>
  );
};

export default NewUserForm;
