import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button, Spinner } from "reactstrap";

const EditUserEventForm = ({ isOpen, toggle, event, onSave }) => {
  const [title, setTitle] = useState(event.title || "");
  const [description, setDescription] = useState(event.description || "");
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const updatedEvent = {
      ...event,
      title,
      description,
    };
    await onSave(updatedEvent);
    setLoading(false);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Event</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="eventTitle">Title</Label>
            <Input
              type="text"
              id="eventTitle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter event title"
            />
          </FormGroup>
          <FormGroup>
            <Label for="eventDescription">Description</Label>
            <Input
              type="textarea"
              id="eventDescription"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter event description"
            />
          </FormGroup>
          <Button color="primary" onClick={handleSave} disabled={loading}>
            {loading ? <Spinner size="sm" /> : "Save"}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditUserEventForm;
