import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Screens
import Dashboard from "../pages/Dashboard/index"
import Users from "../pages/Users/Users"
import User from "../pages/Users/User"
import Events from "../pages/Events/Events"
import Event from "../pages/Events/Event"
import Reports from "../pages/Reports/Reports"
import Feedback from "../pages/Feedback/Feedback"
import Thomas from "../pages/Thomas/Thomas"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },
  { path: "/profile", component: <UserProfile/> },
  { path: "/users", component: <Users/> },
  { path: "/user/:id", component: <User/>},
  { path: "/events", component: <Events/> },
  { path: "/event/:id", component: <Event/>},
  { path: "/reports", component: <Reports/> },
  { path: "/feedback", component: <Feedback/> },
  { path: "/thomas", component: <Thomas/> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
]

export { authProtectedRoutes, publicRoutes }

