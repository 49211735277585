
import { chatGPTAPI } from './chatGPTAPI';
import { fetchSocialImg } from '../../thomas_helper';

export const generateEventDetails = async (user) => {
  try {
    const interests = user.interests && user.interests.length > 0 ? user.interests.join(', ') : 'various topics';
    const prompt = `
You are to generate an engaging event title and description suitable for ${user.firstName} who is interested in ${interests}. Be natural, human, make typos.
- Short title, max 60 characters.
- Short description, max 150 characters.
- The event should be appealing, spontaneous, short, and appropriate for someone in ${user.currentCity}.
- Something basic, like going to a main spot of the city, having drinks, or doing sports, nothing complicated.
- The description should not try to sell the plan, but instead convey the energy of "I'm doing this, if someone wants to join, they are welcome."
- The language output should be ${user.lang}.

**Provide ONLY the result in JSON format with "title" and "description" fields. Do not include any additional text or explanation. Do not include code formatting or markdown.**

Example:
{
  "title": "Your title here",
  "description": "Your description here"
}
`;

    const response = await chatGPTAPI({
      messages: [
        {
          role: 'user',
          content: prompt,
        },
      ]
    });

    return response;
  } catch (error) {
    console.error('Error generating event details with ChatGPT:', error);
    return null;
  }
};


export const prepareEventData = async (user, eventDetails) => {
  const { title, description } = eventDetails;
  const { bgImgLink, bgImgData } = await fetchSocialImg();

  // Prepare event data matching the backend expectations
  const eventData = {
    title,
    description,
    amountOfUsers: "1", // Or any default value
    chatBGImg: bgImgLink || '',
    chatBGImgData: bgImgData,
    location: {
      lat: user.location?.coordinates?.[1] || 0,
      lon: user.location?.coordinates?.[0] || 0,
    },
    city: user.currentCity || 'Unknown City',
    createdBy: {
      user: {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    },
    users: {
      active: [{
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        profileImages: user.profileImages,
      }],
      waitList: [],
      leftOut: [],
      blocked: [],
    },
    status: 'active',
  };

  return eventData;
};