module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
  },
  googleMapsAPIKey: 'AIzaSyBmCEWuj8e6ANs8rNCaG1VcHK34Fi1Icwk',
  chatGPTReamikAPIKey: "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",
  chatGPTModel: 'gpt-4o',
  SECRET: "",
  UPLOAD_API_URL: 'https://bydg7vdvld.execute-api.eu-west-3.amazonaws.com/upload/image',
  IMAGE_BASE_URL: 'https://sc-appm-prx.s3.eu-west-3.amazonaws.com/images/profile',
  // LOCAL
  // ADMIN_BE_API_URL: 'http://localhost:4000',
  // APP_BE_API_URL: 'http://192.168.1.174:3000/api',
  // DEV
  // ADMIN_BE_API_URL: 'https://m426m3vupg.execute-api.eu-west-3.amazonaws.com/default/DEV-admin',
  // APP_BE_API_URL: 'https://vg0anaa3cg.execute-api.eu-west-3.amazonaws.com/default/DEV-api',
  // PROD
  ADMIN_BE_API_URL: 'https://t12khapmye.execute-api.eu-west-3.amazonaws.com/',
  APP_BE_API_URL: 'https://vb94l8xk52.execute-api.eu-west-3.amazonaws.com/api',

  unsplash: 'kZHhGO6Nqm0SOXwxCaUFHdN-bUk7cO46X8ORhR8ulO8',
  firebaseDEVConfig: {
    apiKey: "AIzaSyC13PwQW_h8g2MlMo8tYYiQHLa1wYBAnrw",
    authDomain: "reamik-production-d779c.firebaseapp.com",
    databaseURL: "https://reamik-production-d779c.firebaseio.com",
    projectId: "reamik-production-d779c",
    storageBucket: "reamik-production-d779c.appspot.com",
    messagingSenderId: "375099172493",
    appId: "1:375099172493:web:7b973391ed3060cd9664c4",
    measurementId: "G-QDQEC82JXQ"
  },
  firebasePRODConfig: {
    apiKey: "AIzaSyAyjyGu9gsCAumV9-KPIrz1l76zQE2Mp2g",
    authDomain: "reamik.firebaseapp.com",
    databaseURL: "https://reamik-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "reamik",
    storageBucket: "reamik.appspot.com",
    messagingSenderId: "384685749315",
    appId: "1:384685749315:web:69ce5c92b8fd0870dc10f6",
    measurementId: "G-JFQ81XWG7V"
  },
  assistant_create_user: 'asst_ebUIO1y44T7FOTTsss7nI1Tt',
  assistant_reply_chat: 'asst_pl9GF9VTnWk8fMoy8DGwfQy7',
  MIN_REPLY_DELAY: 1,
  MAX_REPLY_DELAY: 30,
}
