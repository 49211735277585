import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getThomasUsers as onGetUsers,
  addNewThomasUser,
  getNearbyEvents as onGetNearbyEvents,
  updateThomasUser
} from "store/thomas/actions";
import ThomasUsers from "./ThomasUsers";
import ThomasUserDetail from "./ThomasUserDetail";
import NewUserForm from "./NewUserForm";
import { ToastContainer, toast } from "react-toastify";
import useStartThomas from "../../hooks/useStartThomas";
import { GET_THOMAS_USER_PROFILE_SUCCESS } from "store/thomas/actionTypes";

const Thomas = () => {
  const dispatch = useDispatch();

  // State variables
  const [showForm, setShowForm] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  const { users, usersEventsNearby } = useSelector((state) => state.thomas);

  const [usersByCity, setUsersByCity] = useState({});

  const selectedUser = users?.find((user) => user._id === selectedUserId);
  const nearbyEvents = usersEventsNearby[selectedUserId] || [];

  const { startThomas, starting, currentAction, stopThomas, isRunning } = useStartThomas();

  // Fetch users on component mount
  useEffect(() => {
    dispatch(onGetUsers());
  }, []);

  // Fetch nearby events when a user is selected
  useEffect(() => {
    const fetchNearbyEvents = async () => {
      if (selectedUserId && selectedUser) {
        const { coordinates } = selectedUser.location || {};
        const latitude = coordinates ? coordinates[1] : null;
        const longitude = coordinates ? coordinates[0] : null;

        if (latitude && longitude) {
          dispatch(
            onGetNearbyEvents({
              userId: selectedUser._id,
              latitude,
              longitude,
              radius: 5000, // Adjust the radius as needed
            })
          );
        }
      }
    };

    fetchNearbyEvents();
  }, [selectedUserId, selectedUser]);

  // Group users by city whenever users data changes
  useEffect(() => {
    const groupUsersByCity = async () => {
      if (users && users.length > 0) {
        console.log("Users:", users);

        const result = {};
        for (const user of users) {
          const defaultCity = "Unknown City";
          let city = user.location && user.location.city || defaultCity;

          if (city === defaultCity) {
            city = await getUserCity(user) || defaultCity;

            if (city !== defaultCity) {
              dispatch(updateThomasUser({
                ...user,
                location: {
                  ...user.location,
                  city
                }
              }));
            }
          }

          if (!result[city]) {
            result[city] = [];
          }
          result[city].push(user);
        }
        setUsersByCity(result);

        // Automatically select the first city and first user
        const cities = Object.keys(result);
        if (!selectedCity && cities.length >= 1) {
          setSelectedCity(cities[0]);

          const firstUser = result[cities[0]][0];
          if (firstUser) {
            setSelectedUserId(firstUser._id);
          }
        }
        setLoading(false);
      } else {
        setUsersByCity({});
        setSelectedCity(null);
        setSelectedUserId(null);
      }
    };

    groupUsersByCity();
  }, [users, selectedCity]);

  // Function to extract city from user's location using Nominatim API
  const getUserCity = async (user) => {
    const lat = user.location?.coordinates?.[1] || -34.9011; // Default latitude
    const lon = user.location?.coordinates?.[0] || -56.1645; // Default longitude

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      const data = await response.json();
      if (data && data.address) {
        return (
          data.address.city ||
          data.address.town ||
          data.address.village ||
          "Unknown City"
        );
      }
    } catch (error) {
      console.error("Error fetching city for user:", error);
    }
    return "Unknown City";
  };

  // Handle city selection
  const handleCitySelect = (city) => {
    setSelectedCity(city);

    // Select the first user in the selected city
    const cityUsers = usersByCity[city];
    if (cityUsers && cityUsers.length > 0) {
      setSelectedUserId(cityUsers[0]._id);
    } else {
      setSelectedUserId(null);
    }
  };

  // Handle user selection
  const handleUserSelect = (userId) => {
    const userData = users.find((user) => user._id === userId);
    setSelectedUserId(userId);
    dispatch({ type: GET_THOMAS_USER_PROFILE_SUCCESS, payload: userData})
  };

  // Toggle the new user form
  const toggleForm = () => {
    console.log("Toggle form", showForm);
    setShowForm(!showForm);
  };

  // Handle saving a new user
  const handleSaveUser = (newUserData) => {
    dispatch(addNewThomasUser(newUserData));
    dispatch(onGetUsers());
    toast.success("New user added successfully!");
  };

  const renderLoading = () => {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  };

  return (
    <div className="page-content thomas">
      <Container fluid className="thomas-container">
        <div className="top-bar d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center">
            <Button
              color="primary"
              onClick={toggleForm}
              className="add-user-btn me-3"
            >
              <i className="bx bx-plus"></i> Add User
            </Button>
            <div className="city-buttons d-flex flex-wrap">
              {Object.keys(usersByCity).map((city) => (
                <Button
                  key={city}
                  onClick={() => handleCitySelect(city)}
                  className={`city-button me-2 mb-2 ${
                    selectedCity === city ? "city-button-selected" : ""
                  }`}
                  color={selectedCity === city ? "success" : "outline-primary"}
                >
                  <i className="bx bx-building-house"></i> {city}
                  <span className="user-count ms-2">
                    ({usersByCity[city].length})
                  </span>
                </Button>
              ))}
            </div>
          </div>

          <Button
            color="secondary"
            onClick={isRunning ? stopThomas : startThomas}
            className="start-thomas-btn"
          >
            {starting ? (
              <>
                <Spinner size="sm" color="light" /> Stop Thomas
              </>
            ) : (
              <>
                <i className="bx bx-atom"></i> Start Thomas
              </>
            )}
          </Button>
        </div>

        {/* Display current action */}
        {currentAction && (
          <div className="current-action mb-3">
            <strong>Current Action:</strong> {currentAction}
          </div>
        )}

        <Row>
          {showForm ? (
            <Col xs={12}>
              <NewUserForm onSave={handleSaveUser} toggleForm={toggleForm} />
            </Col>
          ) : !users || users.length === 0 ? (
            <Col xs={12} className="no-users text-center">
              <p>No Thomas users found.</p>
            </Col>
          ) : (
            <>
              <Col xs={12} md={4} className="user-list-column">
                {selectedCity && usersByCity[selectedCity] ? (
                  <ThomasUsers
                    users={usersByCity[selectedCity]}
                    selectedUserId={selectedUserId}
                    onUserSelect={handleUserSelect}
                  />
                ) : loading ? renderLoading() : (
                  <p>No users available in the selected city.</p>
                )}
              </Col>
              {/* Display the related nearby events */}
              <Col xs={12} md={8} className="user-detail-column">
                {selectedUser ? (
                  <ThomasUserDetail user={selectedUser} nearbyEvents={nearbyEvents} />
                ) : loading ? renderLoading() : (
                  <p>Select a user to see details.</p>
                )}
              </Col>
            </>
          )}
        </Row>

        <ToastContainer />
      </Container>
    </div>
  );
};

export default Thomas;
