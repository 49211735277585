import React, { useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  Spinner,
  Alert,
  Button,
} from "reactstrap";
import ThomasUserEventDetail from "../../pages/Thomas/ThomasUserEventDetail";

const EventsList = ({
  events,
  userId,
  currentUser,
  acceptUser,
  acceptingUserId,
  acceptUserLoading,
  handleEditEvent,
  handleDeleteEvent,
  isOwner,
}) => {
  // State to track expanded events
  const [expandedEvents, setExpandedEvents] = useState({});

  // Function to toggle event expansion
  const toggleEventExpansion = (eventId) => {
    setExpandedEvents((prevExpanded) => ({
      ...prevExpanded,
      [eventId]: !prevExpanded[eventId],
    }));
  };

  return (
    <>
      {events && events.length > 0 ? (
        <ListGroup flush>
          {events.map((event) => (
            <ListGroupItem key={event._id} className="event-list-item">
              <div
                className="d-flex justify-content-between align-items-center header-toggle"
                onClick={() => toggleEventExpansion(event._id)}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <strong>{event.title || ""}</strong> -{" "}
                  {new Date(event.createdAt || "").toLocaleDateString()}
                </div>
                <i
                  className={`bx ${
                    expandedEvents[event._id] ? "bx-chevron-up" : "bx-chevron-down"
                  }`}
                ></i>
              </div>

              {expandedEvents[event._id] && (
                <ThomasUserEventDetail
                  event={event}
                  userId={userId}
                  currentUser={currentUser}
                  acceptUser={acceptUser}
                  acceptingUserId={acceptingUserId}
                  acceptUserLoading={acceptUserLoading}
                  handleEditEvent={handleEditEvent}
                  handleDeleteEvent={handleDeleteEvent}
                  isOwner={isOwner}
                />
              )}
            </ListGroupItem>
          ))}
        </ListGroup>
      ) : (
        <p>No events found.</p>
      )}
    </>
  );
};

export default EventsList;
