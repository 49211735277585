import React, { useState, useEffect, useCallback } from 'react';
import {
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Button,
  Spinner
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updateThomasUser, getThomasUsers } from '../../store/thomas/actions';
import GooglePlaces from '../../components/Common/GooglePlaces';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { toast } from 'react-toastify';
import { ObjectId } from 'bson';
import { getInterests, getLanguages } from '../../helpers/thomas_helper';
import ImageGalleryModal from "../../components/Common/ImageGalleryModal";
import ImageUploader from '../../components/Common/ImageUploader';
import { interactWithAssistantUserData } from '../../helpers/chat/chatGPT/interactWithAssistant';

const EditThomasUser = ({ user, onCancel }) => {
  const dispatch = useDispatch();
  const [interestsList, setInterestsList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [loadingGPT, setLoadingGPT] = useState(false);
  const [loadingInterests, setLoadingInterests] = useState(false);
  const [loadingLanguages, setLoadingLanguages] = useState(false);
  const [uploadedImages, setUploadedImages] = useState(user.pictures || []);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000;

  useEffect(() => {
    // Populate initial location values
    if (user.bornCity && user.bornCountry) {
      validation.setFieldValue('bornCity', user.bornCity);
      validation.setFieldValue('bornCountry', user.bornCountry);
      validation.setFieldValue('bornLocation', { name: `${user.bornCity}, ${user.bornCountry}` });
    }

    if (user.currentCity && user.currentCountry) {
      validation.setFieldValue('currentCity', user.currentCity);
      validation.setFieldValue('currentCountry', user.currentCountry);
      validation.setFieldValue('currentLocation', { name: `${user.currentCity}, ${user.currentCountry}` });
    }
  }, [user]);

  // Function to fetch interests with retry
  const fetchInterests = useCallback(async (retryCount = 0) => {
    try {
      setLoadingInterests(true);
      const interests = await getInterests();
      if (interests?.results?.length > 0) {
        setInterestsList(interests.results);
        setLoadingInterests(false);
      } else {
        throw new Error("No interests found");
      }
    } catch (error) {
      if (retryCount < MAX_RETRIES) {
        setTimeout(() => {
          fetchInterests(retryCount + 1);
        }, RETRY_DELAY);
      } else {
        setLoadingInterests(false);
        toast.error("Failed to load interests.", { autoClose: 3000 });
      }
    }
  }, []);

  // Function to fetch languages with retry
  const fetchLanguages = useCallback(async (retryCount = 0) => {
    try {
      setLoadingLanguages(true);
      const languages = await getLanguages();
      if (languages?.length > 0) {
        setLanguagesList(languages);
        setLoadingLanguages(false);
      } else {
        throw new Error("No languages found");
      }
    } catch (error) {
      if (retryCount < MAX_RETRIES) {
        setTimeout(() => {
          fetchLanguages(retryCount + 1);
        }, RETRY_DELAY);
      } else {
        setLoadingLanguages(false);
        toast.error("Failed to load languages.", { autoClose: 3000 });
      }
    }
  }, []);

  useEffect(() => {
    fetchInterests();
    fetchLanguages();
  }, [fetchInterests, fetchLanguages]);

  const handleCompleteWithChatGPT = async () => {
    setLoadingGPT(true);
    console.log("handleCompleteWithChatGPT");

    const promptParameters = thomasPrompt_newUser(
      validation.values.lang || "English",
      validation.values.currentLocation?.name || "Barcelona"
    );
    console.log("Prompt Parameters:", promptParameters);

    try {
      const messages = [
        { role: "user", content: promptParameters } // Send parameters as a string
      ];
      const chatGPTResponse = await interactWithAssistantUserData(messages);
      setLoadingGPT(false);

      if (chatGPTResponse) {
        let content = chatGPTResponse;

        if (content) {
          try {
            // Assuming the assistant returns a JSON object
            validation.setValues({
              firstName: content.firstName || "",
              lastName: content.lastName || "",
              enabled: content.enabled || false,
              gender: content.gender || "",
              email: content.email || "",
              description: content.description || "",
              bornCity: content.bornCity || "",
              bornCountry: content.bornCountry || "",
              currentCity: content.currentCity || "",
              currentCountry: content.currentCountry || "",
              bornLocation: {
                name: content.bornCity ? `${content.bornCity}, ${content.bornCountry}` : "",
                coordinates: content.bornLocation?.coordinates || []
              },
              currentLocation: {
                name: content.currentCity ? `${content.currentCity}, ${content.currentCountry}` : "",
                coordinates: content.currentLocation?.coordinates || []
              },
              location: {
                name: content.location?.name || "",
                coordinates: content.location?.coordinates || []
              },
              interests: content.interests || [],
              languages: content.languages || [],
              pictures: content.pictures || [],
              dateOfBirth: content.dateOfBirth || "",
              lang: content.lang || ""
            });

            // Handle uploaded images if provided
            if (content.pictures && Array.isArray(content.pictures)) {
              const uploaded = content.pictures.map(url => ({
                _id: new ObjectId().toString(),
                src: url,
                title: 'Generated Picture',
                createdAt: Date.now(),
                uploading: false,
              }));
              setUploadedImages(uploaded);
            }

          } catch (error) {
            console.error("Error setting form values:", error.message);
            toast.error("Failed to set form values from assistant response.", { autoClose: 3000 });
          }
        } else {
          console.error("Content is undefined or empty");
          toast.error("Assistant failed to generate a response.", { autoClose: 3000 });
        }
      } else {
        console.error("Invalid ChatGPT response format", chatGPTResponse);
        toast.error("Received an invalid response from the assistant.", { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error interacting with assistant:", error);
      setLoadingGPT(false);
      toast.error("An error occurred while interacting with the assistant.", { autoClose: 3000 });
    }
  };

  const onFormSubmit = async (values) => {
    // Prevent submission if images are still uploading
    if (uploadedImages.some((image) => image.uploading)) {
      toast.error('Please wait for images to finish uploading before submitting.', { autoClose: 3000 });
      return;
    }

    const { bornLocation: valuesBornLocation, currentLocation: valuesCurrentLocation, location: valuesLocation, ...props } = values;

    const bornLocation = valuesBornLocation?.name ? valuesBornLocation : user.bornLocation;
    const currentLocation = valuesCurrentLocation?.name ? valuesCurrentLocation : user.currentLocation;
    const location = valuesLocation?.coordinates?.length ? valuesLocation : user.location;

    const [bornCity, bornCountry] = bornLocation && bornLocation.name
      ? bornLocation.name.split(',').map((str) => str.trim())
      : [user.bornCity, user.bornCountry];

    const [currentCity, currentCountry] = currentLocation && currentLocation.name
      ? currentLocation.name.split(',').map((str) => str.trim())
      : [user.currentCity, user.currentCountry];

    // Filter out images that are still uploading
    const uploadedImagesFiltered = uploadedImages.filter((image) => !image.uploading);

    const updatedUser = {
      ...user,
      ...props,
      _id: user._id,
      location: {
        type: 'Point',
        coordinates: location?.coordinates || [],
      },
      bornCity: bornCity || user.bornCity || '',
      bornCountry: bornCountry || user.bornCountry || '',
      currentCity: currentCity || user.currentCity || '',
      currentCountry: currentCountry || user.currentCountry || '',
      pictures: uploadedImagesFiltered.map((image) => ({
        _id: image._id || new ObjectId().toString(),
        createdAt: image.createdAt || Date.now(),
        title: image.title || 'User uploaded picture',
        src: image.src || '',
      })),
      profileImages: uploadedImagesFiltered.map((image) => image.src),
      profileImage: uploadedImagesFiltered[0]?.src || user.profileImage,
    };

    try {
      console.log('Edit Updated User:', updatedUser);

      dispatch(updateThomasUser(updatedUser));
      dispatch(getThomasUsers());

      if (onCancel) onCancel();
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Failed to update user', { autoClose: 2000 });
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      enabled: user.enabled || false,
      gender: user.gender || '',
      email: user.email || '',
      description: user.description || '',
      bornCity: user.bornCity || '',
      bornCountry: user.bornCountry || '',
      currentCity: user.currentCity || '',
      currentCountry: user.currentCountry || '',
      bornLocation: user.bornLocation || {
        name: user.bornCity && user.bornCountry ? `${user.bornCity}, ${user.bornCountry}` : '',
        coordinates: [],
      },
      currentLocation: user.currentLocation || {
        name: user.currentCity && user.currentCountry ? `${user.currentCity}, ${user.currentCountry}` : '',
        coordinates: [],
      },
      location: user.location || null,
      interests: user.interests || [],
      languages: user.languages || [],
      dateOfBirth: user.dateOfBirth || '',
      lang: user.lang || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please enter your first name'),
      lastName: Yup.string().required('Please enter your last name'),
      email: Yup.string()
        .email('Please enter a valid email')
        .required('Please enter your email'),
      dateOfBirth: Yup.string().required('Please enter your date of birth'),
      bornCity: Yup.string().required('Please enter your birth city'),
      bornCountry: Yup.string().required('Please enter your birth country'),
      currentCity: Yup.string().required('Please enter your current city'),
      currentCountry: Yup.string().required('Please enter your current country'),
      // Add other validations as needed
    }),
    onSubmit: onFormSubmit,
  });

  const handleLocationChange = async (field, selectedPlace) => {
    console.log('Selected Place:', field, selectedPlace);

    if (selectedPlace && selectedPlace.label) {
      try {
        const results = await geocodeByAddress(selectedPlace.label);
        const { lat, lng } = await getLatLng(results[0]);
        console.log('Successfully got latitude and longitude', { lat, lng });

        // Extract city and country from the address components
        const addressComponents = results[0].address_components;
        let city = '';
        let country = '';

        addressComponents.forEach(component => {
          const types = component.types;
          if (types.includes('locality')) {
            city = component.long_name;
          }
          if (types.includes('country')) {
            country = component.long_name;
          }
        });

        const coordinates = [lng, lat]; // MongoDB expects [lng, lat]
        console.log('coordinates', coordinates);

        // Set city and country fields based on the selected location
        if (field === 'bornLocation') {
          validation.setFieldValue('bornCity', city);
          validation.setFieldValue('bornCountry', country);
        } else if (field === 'currentLocation') {
          validation.setFieldValue('currentCity', city);
          validation.setFieldValue('currentCountry', country);
        }

        validation.setFieldValue(field, {
          name: selectedPlace.label,
          coordinates,
        });
      } catch (error) {
        console.error('Error fetching coordinates:', error);
        toast.error('Failed to get location details.', { autoClose: 3000 });
        validation.setFieldValue(field, { name: selectedPlace.label, coordinates: [] });
      }
    } else {
      validation.setFieldValue(field, { name: '', coordinates: [] });
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImageIndex(imageSrc);
    toggleModal();
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      className="update-user-form elegant-form"
      id="thomasForm"
    >
      <Row>
        <Col xs={12} md={4}>
          {/* First Name */}
          <div className="mb-3 mt-3">
            <Label className="form-label">First Name</Label>
            <Input
              name="firstName"
              type="text"
              placeholder="Insert First Name"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.firstName}
              invalid={validation.touched.firstName && validation.errors.firstName}
            />
            {validation.touched.firstName && validation.errors.firstName && (
              <FormFeedback>{validation.errors.firstName}</FormFeedback>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Last Name */}
          <div className="mb-3 mt-3">
            <Label className="form-label">Last Name</Label>
            <Input
              name="lastName"
              type="text"
              placeholder="Insert Last Name"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lastName}
              invalid={validation.touched.lastName && validation.errors.lastName}
            />
            {validation.touched.lastName && validation.errors.lastName && (
              <FormFeedback>{validation.errors.lastName}</FormFeedback>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Email */}
          <div className="mb-3 mt-3">
            <Label className="form-label">Email</Label>
            <Input
              name="email"
              type="email"
              placeholder="Insert Email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email}
              invalid={validation.touched.email && validation.errors.email}
            />
            {validation.touched.email && validation.errors.email && (
              <FormFeedback>{validation.errors.email}</FormFeedback>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          {/* Gender */}
          <div className="mb-3">
            <Label className="form-label">Gender</Label>
            <Input
              name="gender"
              type="select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.gender}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </Input>
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Date of Birth */}
          <div className="mb-3">
            <Label className="form-label">Date of Birth</Label>
            <Input
              name="dateOfBirth"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.dateOfBirth}
              invalid={validation.touched.dateOfBirth && validation.errors.dateOfBirth}
            />
            {validation.touched.dateOfBirth && validation.errors.dateOfBirth && (
              <FormFeedback>{validation.errors.dateOfBirth}</FormFeedback>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Enabled */}
          <div className="mb-3">
            <Label className="form-label">Enabled</Label>
            <div className="border p-2 rounded d-flex align-items-center">
              <Input
                name="enabled"
                type="checkbox"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                checked={validation.values.enabled}
                className="me-2"
              />
              <span>Enabled</span>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          {/* Born Location */}
          <div className="mb-3">
            <Label className="form-label">Born Location</Label>
            <GooglePlaces
              onPress={value => handleLocationChange('bornLocation', value)}
              placeholder="Enter birth city or country"
              initialValue={validation.values.bornLocation?.name || ""}
            />
            {validation.touched.bornCity && validation.errors.bornCity && (
              <FormFeedback className="d-block">{validation.errors.bornCity}</FormFeedback>
            )}
            {validation.touched.bornCountry && validation.errors.bornCountry && (
              <FormFeedback className="d-block">{validation.errors.bornCountry}</FormFeedback>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Current Location */}
          <div className="mb-3">
            <Label className="form-label">Current Location</Label>
            <GooglePlaces
              onPress={value => handleLocationChange('currentLocation', value)}
              placeholder="Enter current city or country"
              initialValue={validation.values.currentLocation?.name || ""}
            />
            {validation.touched.currentCity && validation.errors.currentCity && (
              <FormFeedback className="d-block">{validation.errors.currentCity}</FormFeedback>
            )}
            {validation.touched.currentCountry && validation.errors.currentCountry && (
              <FormFeedback className="d-block">{validation.errors.currentCountry}</FormFeedback>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Location */}
          <div className="mb-3">
            <Label className="form-label">Location</Label>
            <GooglePlaces
              onPress={value => handleLocationChange('location', value)}
              placeholder="Enter location"
              initialValue={validation.values.location?.name || ""}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          {/* Description */}
          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <Input
              name="description"
              type="textarea"
              placeholder="Insert Description"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description}
            />
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Interests */}
          <div className="mb-3">
            <Label className="form-label">Interests</Label>
            {loadingInterests ? (
              <Spinner size="sm" color="primary" />
            ) : (
              <Input
                name="interests"
                type="select"
                multiple
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.interests}
                style={{ height: '150px', overflowY: 'auto' }} // Making the selector taller
              >
                {interestsList?.map(interest => (
                  <option key={interest.id} value={interest.id}>
                    {String.fromCodePoint(parseInt(interest.emoji, 16))} {interest.id}
                  </option>
                ))}
              </Input>
            )}
            {validation.touched.interests && validation.errors.interests && (
              <FormFeedback>{validation.errors.interests}</FormFeedback>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          {/* Languages */}
          <div className="mb-3">
            <Label className="form-label">Languages</Label>
            {loadingLanguages ? (
              <Spinner size="sm" color="primary" />
            ) : (
              <Input
                name="languages"
                type="select"
                multiple
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.languages}
                style={{ height: '150px', overflowY: 'auto' }} // Making the selector taller
              >
                {languagesList?.map(language => (
                  <option key={language.id} value={language.id}>
                    {language.name}
                  </option>
                ))}
              </Input>
            )}
            {validation.touched.languages && validation.errors.languages && (
              <FormFeedback>{validation.errors.languages}</FormFeedback>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {/* Image Uploader */}
          <div className="mb-3">
            <Label className="form-label">Pictures</Label>
            <ImageUploader
              userId={user._id}
              uploadedImages={uploadedImages}
              setUploadedImages={setUploadedImages}
              maxFiles={10} // Adjust as needed
              accept="image/*"
            />
          </div>
        </Col>
      </Row>

      {/* Image Modal */}
      <ImageGalleryModal
        isOpen={selectedImageIndex !== null}
        toggle={toggleModal}
        images={uploadedImages}
        currentIndex={selectedImageIndex}
        setCurrentIndex={setSelectedImageIndex}
      />

      <Row>
        <Col xs={12}>
          <div className="text-end">
            <Button
              type="button"
              color="primary"
              disabled={loadingGPT}
              onClick={handleCompleteWithChatGPT}
            >
              {loadingGPT ? <Spinner size="sm" /> : 'Complete with ChatGPT'}
            </Button>
            <Button
              type="submit"
              color="success"
              className="ms-2"
              disabled={loadingGPT || uploadedImages.some((image) => image.uploading)} // Disable if images are uploading
            >
              Save Changes
            </Button>
            <Button type="button" color="secondary" className="ms-2" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default EditThomasUser;
