import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import { ADMIN_BE_API_URL, APP_BE_API_URL, UPLOAD_API_URL } from "../config";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
// const ADMIN_BE_API_URL = 'https://vx9a03bmjd.execute-api.eu-west-3.amazonaws.com/api'
// const ADMIN_BE_API_URL = "http://localhost:3000/";

export const axiosApi = axios.create({
  baseURL: ADMIN_BE_API_URL
});

// Not used yet
export const axiosApiUpload = axios.create({
  baseURL: UPLOAD_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const axiosAPPApi = axios.create({
  baseURL: APP_BE_API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// const token = localStorage.getItem("authUserToken"); // Replace with actual storage key

// export const axiosAPPApi = axios.create({
//   baseURL: APP_BE_API_URL,
//   withCredentials: true,
//   headers: {
//     "Content-Type": "application/json",
//     [token ? "sc-uxr-jtkew" : ""]: token, // Include the token
//     // "sc-uxr-jtkew": token, // Include the token
//   },
// });

// export const axiosApi = axios.create({
//   baseURL: ADMIN_BE_API_URL,
//   withCredentials: true,
// });

axiosApi.defaults.headers.common["sc-uxr-jtkew"] = token;
axiosAPPApi.defaults.headers.common["sc-uxr-jtkew"] = token;
axiosApi.defaults.headers.common["Accept"] = "*/*";

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  // console.log('url', url);
  return await axiosApi
    .get(url, { ...config })
    .then((response) => {
      return response.data
    });
}

export async function post(url, data, config = {}) {
  // console.log('url', url, 'data', data);
  return axiosApi
    .post(url, data = { ...data }, { ...config })
    .then((response) => {
  return response.data
    }).catch((error) => console.log("error", error));
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  // console.log('url', url);
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
