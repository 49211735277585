import React, { useState, useEffect, useRef } from "react";
import {
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Alert,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserEvent,
  deleteUserEvent,
  acceptUserIntoEvent,
} from "../../store/thomas/actions";
import { toast } from "react-toastify";
import { ADMIN_BE_API_URL } from "../../config";
import io from "socket.io-client";
import { axiosAPPApi } from "../../helpers/api_helper";
import ThomasUserEventDetail from "./ThomasUserEventDetail";
import EditUserEventForm from "./EditUserEventForm";
import {
  createChat,
  getChatData,
  addUserToChat,
  saveChatIdOnEvent,
} from "../../helpers/chat/chatGPT/chatManagement";
import { getEventById } from "../../helpers/events_helper";
import { ACCEPT_USER_INTO_EVENT } from "../../store/thomas/actionTypes";

const ThomasUserEvents = ({ events: initialEvents, userId }) => {
  const dispatch = useDispatch();

  // Access Redux state
  const { users, acceptUserLoading, acceptUserError } = useSelector((state) => state.thomas);
  const currentUser = users.find((user) => user._id === userId);

  // State to track expanded events
  const [expandedEvents, setExpandedEvents] = useState({});
  const [acceptingUserId, setAcceptingUserId] = useState(null);

  // State for Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Loading and error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState(initialEvents || []);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);

  const handleEditEvent = (event) => {
    setEventToEdit(event);
    setEditModalOpen(true);
  };

  const toggleEditModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  const handleSaveEvent = (updatedEvent) => {
    try {
      setLoading(true);
      dispatch(updateUserEvent(updatedEvent, currentUser));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Failed to update event");
    }
  };

  // Sync events with initialEvents prop
  useEffect(() => {
    setEvents(initialEvents || []);
  }, [initialEvents]);

  // Ref to track processed event changes for deduplication
  const processedChangesRef = useRef(new Set());

  // Ref to store the Socket.IO client instance
  const socketRef = useRef(null);

  useEffect(() => {
    // Initialize Socket.IO client only once
    if (!socketRef.current) {
      socketRef.current = io(ADMIN_BE_API_URL);
    }

    const socket = socketRef.current;

    // Listen for event changes
    socket.on("eventChange", (change) => {
      console.log("Received event change:", change);
      handleEventChange(change);
    });

    // Cleanup on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  // Check if the event is relevant to the current user
  const isEventRelevantToUser = (event) => {
    if (!event) return false;

    // Check if the user is the owner
    if (event.ownerId === userId) {
      return true;
    }

    // Check if the user is in active users
    if (event.users.active.some((u) => u._id === userId)) {
      return true;
    }

    // Check if the user is in waitList
    if (event.users.waitList.some((u) => u._id === userId)) {
      return true;
    }

    return false;
  };

  // Accept user into event (local function)
  const acceptTheUserOnEvent = async (eventId, userIdToAccept) => {
    try {
      // Accept user into event
      dispatch({
        type: ACCEPT_USER_INTO_EVENT,
        payload: {
          eventId: eventId,
          userId: userIdToAccept,
          user: currentUser
        }
      });

      // Fetch updated event data
      const updatedEvent = await getEventById(eventId);

      if (!updatedEvent) {
        console.error("Updated event data is null.");
        return;
      }

      // Handle chat
      let chatData;
      console.log("updatedEvent", updatedEvent);

      if (updatedEvent.chatId) {
        // Chat exists, add user to chat
        chatData = await getChatData(updatedEvent.chatId);
        const userToAccept = updatedEvent.users.active.find((u) => u._id === userIdToAccept);
        if (userToAccept) {
          await addUserToChat(currentUser, userToAccept, chatData);
        }
      } else {
        // Create new chat with active users
        const chatUsers = updatedEvent.users.active;
        chatData = await createChat(currentUser._id, { users: chatUsers });
        // Save chatId to event
        await saveChatIdOnEvent(eventId, chatData.key);
      }

      // Send push notification to the accepted user
      const acceptedUser = updatedEvent.users.active.find((u) => u._id === userIdToAccept);
      if (acceptedUser) {
        await sendTranslatedPushNotificationForUsers(
          [acceptedUser],
          "You've been accepted!",
          "You have been accepted into the event.",
          eventId,
          updatedEvent.title
        );
      }

      toast.success(`User ${acceptedUser.firstName} accepted into the event.`, { autoClose: 2000 });
    } catch (error) {
      console.error(`Error accepting user ${userIdToAccept} into event ${eventId}:`, error);
      toast.error(`Failed to accept user into the event.`, { autoClose: 2000 });
    }
  };

  // Send message inside event
  const sendMessageInsideEvent = (eventId, message) => {
    axiosAPPApi
      .post("/event/message", { eventId, message })
      .then((response) => {
        console.log("Message sent:", response.data);
      })
      .catch((error) => {
        console.error("Failed to send message:", error);
        toast.error("Failed to send message.", { autoClose: 2000 });
      });
  };

  const handleDeleteEvent = async (eventId) => {
    if (window.confirm("Are you sure you want to delete this event x?")) {
      try {
        setLoading(true);
        dispatch(deleteUserEvent(eventId, currentUser.accessToken));
        setLoading(false);
        // Update the events state to remove the deleted event
        setEvents((prevEvents) => prevEvents.filter((event) => event._id !== eventId));
      } catch (err) {
        console.log("Failed to delete event:", err);

        setLoading(false);
        setError("Failed to delete event");
      }
    }
  };

  const handleEventChange = (change) => {
    const { operationType, documentKey, _id } = change;
    const eventId = documentKey._id;

    // Create a unique identifier for the change to prevent duplicates
    const changeIdentifier = `${_id._data}`;

    // Check if this change has already been processed
    if (processedChangesRef.current.has(changeIdentifier)) {
      console.warn(`Duplicate event change detected: ${changeIdentifier}. Ignoring.`);
      return;
    }

    // Mark this change as processed
    processedChangesRef.current.add(changeIdentifier);

    if (operationType === "update") {
      let waitListUpdated = false;
      let activeUsersUpdated = false;
      // const updatedFields = updateDescription.updatedFields;
      // const updatedFieldsKeys = Object.keys(updatedFields);


      // const waitListUpdated = updatedFieldsKeys.some((key) =>
      //   key.startsWith("users.waitList")
      // );
      // const activeUsersUpdated = updatedFieldsKeys.some((key) =>
      //   key.startsWith("users.active")
      // );

      // if (!waitListUpdated && !activeUsersUpdated) {
      //   // Neither waitList nor active users were updated
      //   return;
      // }

      // Fetch the updated event data
      getEventById(eventId).then((updatedEvent) => {
        if (!updatedEvent) {
          // Failed to fetch event data
          return;
        }

        // Check if the event is relevant to the current user
        if (!isEventRelevantToUser(updatedEvent)) {
          // Event is not relevant to the current user
          return;
        }

        // Update the events state
        setEvents((prevEvents) => {
          const eventIndex = prevEvents.findIndex((event) => event._id === eventId);
          if (eventIndex !== -1) {
            // Update existing event
            const updatedEvents = [...prevEvents];
            updatedEvents[eventIndex] = updatedEvent;
            const theNewEventUsers = updatedEvents[eventIndex]?.users;
            const prevEventUsers = prevEvents[eventIndex]?.users;

            if (theNewEventUsers?.waitList?.length !== prevEventUsers?.waitList?.length) {
              waitListUpdated = true;
            } else if (theNewEventUsers?.active?.length !== prevEventUsers?.active?.length) {
              activeUsersUpdated = true;
            }

            return updatedEvents;
          } else {
            // Add new event
            return [...prevEvents, updatedEvent];
          }
        });

        // Implement the specified logic
        // 1. If a new user is added to waitList and we are the owner, accept the user after a random delay
        if (waitListUpdated && updatedEvent.ownerId === userId) {
          // Iterate over waitList and accept each user after a random delay
          updatedEvent.users.waitList.forEach((user) => {
            const delayMs = Math.floor(Math.random() * (30000 - 1000 + 1)) + 1000; // 1-30 seconds
            setTimeout(() => {
              acceptTheUserOnEvent(updatedEvent._id, user._id);
            }, delayMs);
          });
        }

        // 2. If we have been accepted into the event, send "Hey there!" message after a random delay
        if (activeUsersUpdated) {
          const isUserNowActive = updatedEvent.users.active.some((u) => u._id === userId);
          if (isUserNowActive) {
            const delayMs = Math.floor(Math.random() * (30000 - 1000 + 1)) + 1000; // 1-30 seconds
            setTimeout(() => {
              sendMessageInsideEvent(updatedEvent._id, "Hey there!");
            }, delayMs);
          } else {
            // 3. If a new user has been accepted, send "Hola ${userName}" after a random delay
            updatedEvent.users.active.forEach((user) => {
              if (user._id !== userId) {
                const delayMs = Math.floor(Math.random() * (30000 - 1000 + 1)) + 1000; // 1-30 seconds
                setTimeout(() => {
                  sendMessageInsideEvent(updatedEvent._id, `Hola ${user.firstName}`);
                }, delayMs);
              }
            });
          }
        }
      });
    }

    // Optionally, handle other operation types (insert, delete) if necessary
  };

  // Function to toggle event expansion
  const toggleEventExpansion = (eventId) => {
    setExpandedEvents((prevExpanded) => ({
      ...prevExpanded,
      [eventId]: !prevExpanded[eventId],
    }));
  };

  // Handlers for Modal
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    toggleModal();
  };

  const acceptUser = async (eventId, userIdToAccept) => {
    setAcceptingUserId(userIdToAccept);

    // Dispatch action to accept user into event
    dispatch(acceptUserIntoEvent(eventId, userIdToAccept, currentUser.accessToken));

    // Fetch updated event data
    const updatedEvent = await getEventById(eventId);

    if (!updatedEvent) {
      console.error("Updated event data is null.");
      return;
    }

    // Handle chat
    let chatData;
    if (updatedEvent.chatId) {
      // Chat exists, add user to chat
      chatData = await getChatData(updatedEvent.chatId);
      const userToAccept = updatedEvent.users.active.find((u) => u._id === userIdToAccept);
      if (userToAccept) {
        await addUserToChat(currentUser, userToAccept, chatData);
      }
    } else {
      // Create new chat with active users
      const chatUsers = updatedEvent.users.active;
      chatData = await createChat(currentUser._id, { users: chatUsers });
      // Save chatId to event
      await saveChatIdOnEvent(eventId, chatData.key);
    }

    // Send push notification to the accepted user
    const acceptedUser = updatedEvent.users.active.find((u) => u._id === userIdToAccept);
    if (acceptedUser) {
      await sendTranslatedPushNotificationForUsers(
        [acceptedUser],
        "You've been accepted!",
        "You have been accepted into the event.",
        eventId,
        updatedEvent.title
      );
    }

    toast.success(`User ${acceptedUser.firstName} accepted into the event.`, { autoClose: 2000 });
  };

  // Reset acceptingUserId when loading is false
  useEffect(() => {
    if (!acceptUserLoading && acceptingUserId) {
      setAcceptingUserId(null);
    }
  }, [acceptUserLoading, acceptingUserId]);

  // Handle accept user error
  useEffect(() => {
    if (acceptUserError && acceptingUserId) {
      toast.error("Failed to accept user", { autoClose: 2000 });
      setAcceptingUserId(null);
    }
  }, [acceptUserError, acceptingUserId]);

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      {loading && (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      )}
      {!loading && (
        <>
          <h5 className="mt-3">Participated Events</h5>
          {events.length > 0 ? (
            <ListGroup flush>
              {events.map((event) => (
                <ListGroupItem key={event._id} className="event-list-item">
                  <div
                    className="d-flex justify-content-between align-items-center header-toggle"
                    onClick={() => toggleEventExpansion(event._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <strong>{event.title || ""}</strong> -{" "}
                      {new Date(event.createdAt || "").toLocaleDateString()}
                    </div>
                    <i
                      className={`bx ${
                        expandedEvents[event._id] ? "bx-chevron-up" : "bx-chevron-down"
                      }`}
                    ></i>
                  </div>

                  {eventToEdit && (
                    <EditUserEventForm
                      isOpen={editModalOpen}
                      toggle={toggleEditModal}
                      event={eventToEdit}
                      onSave={handleSaveEvent}
                    />
                  )}
                  {expandedEvents[event._id] && (
                    <ThomasUserEventDetail
                      event={event}
                      userId={userId}
                      currentUser={currentUser}
                      acceptUser={acceptUser}
                      acceptingUserId={acceptingUserId}
                      acceptUserLoading={acceptUserLoading}
                      handleEditEvent={handleEditEvent}
                      handleDeleteEvent={handleDeleteEvent}
                    />
                  )}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <p>No events found for this user.</p>
          )}

          {/* Modal for Image Preview */}
          <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>Picture Preview</ModalHeader>
            <ModalBody className="text-center">
              {selectedImage && (
                <img src={selectedImage} alt="Selected" className="img-fluid" />
              )}
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

export default ThomasUserEvents;
