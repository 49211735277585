import {
  GET_THOMAS_USERS,
  GET_THOMAS_USERS_SUCCESS,
  GET_THOMAS_USERS_FAIL,
  ADD_NEW_THOMAS_USER,
  ADD_THOMAS_USER_SUCCESS,
  ADD_THOMAS_USER_FAIL,
  UPDATE_THOMAS_USER,
  UPDATE_THOMAS_USER_SUCCESS,
  UPDATE_THOMAS_USER_FAIL,
  DELETE_THOMAS_USER,
  DELETE_THOMAS_USER_SUCCESS,
  DELETE_THOMAS_USER_FAIL,
  GET_THOMAS_USER_PROFILE,
  GET_THOMAS_USER_PROFILE_SUCCESS,
  GET_THOMAS_USER_PROFILE_FAIL,
  GET_USER_EVENTS,
  GET_USER_EVENTS_SUCCESS,
  GET_USER_EVENTS_FAIL,
  ADD_USER_EVENT,
  ADD_USER_EVENT_SUCCESS,
  ADD_USER_EVENT_FAIL,
  UPDATE_USER_EVENT,
  UPDATE_USER_EVENT_SUCCESS,
  UPDATE_USER_EVENT_FAIL,
  DELETE_USER_EVENT,
  DELETE_USER_EVENT_SUCCESS,
  DELETE_USER_EVENT_FAIL,
  SELECT_THOMAS_USER,
  SELECT_THOMAS_USER_SUCCESS,
  SELECT_THOMAS_USER_FAIL,
  ACCEPT_USER_INTO_EVENT,
  ACCEPT_USER_INTO_EVENT_SUCCESS,
  ACCEPT_USER_INTO_EVENT_FAIL,
  GET_NEARBY_EVENTS,
  GET_NEARBY_EVENTS_SUCCESS,
  GET_NEARBY_EVENTS_FAIL,
} from "./actionTypes";

import { tableDefaultState } from "../common/misc.js";

const INIT_STATE = {
  tableData: tableDefaultState,
  users: [],
  usersEvents: {},
  usersEventsNearby: {},
  error: {},
  loading: false,
  acceptUserLoading: false,
  acceptUserError: null,
};

const thomas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_THOMAS_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_THOMAS_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload || [],
      };
    case GET_THOMAS_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Handle adding a new Thomas user
    case ADD_NEW_THOMAS_USER:
      return {
        ...state,
        loading: true,
      };

    case ADD_THOMAS_USER_SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          data: [action.payload, ...state.tableData.data],
        },
        loading: false,
      };

    case ADD_THOMAS_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // Handle fetching Thomas user profile
    case GET_THOMAS_USER_PROFILE:
      return {
        ...state,
        loading: true,
      };

    case GET_THOMAS_USER_PROFILE_SUCCESS:
      // copy array of users and update specific user on it
      const newUsers = state.users.map(user => {
        if (user._id === action.payload._id) {
          return action.payload;
        } else {
          return user;
        }
      });
      return {
        ...state,
        users: newUsers,
        loading: false,
      };

    case GET_THOMAS_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // Handle fetching user events
    case GET_USER_EVENTS:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_EVENTS_SUCCESS:
      return {
        ...state,
        usersEvents: {
          ...state.usersEvents,
          [action.payload.userId]: action.payload.events,
        },
        loading: false,
      };

    case GET_USER_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // Handle adding a user event
    case ADD_USER_EVENT:
      return {
        ...state,
        loading: true,
      };

    case ADD_USER_EVENT_SUCCESS:
      // update the info on the specific event on the events of the userId inside events
      const newEvents = state.usersEvents[action.payload.user._id].map(event =>
        event._id === action.payload._id ? action.payload : event
      );
      return {
        ...state,
        usersEvents: {
          ...state.usersEvents,
          [action.payload.user._id]: newEvents,
        },
        loading: false,
      };

    case ADD_USER_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // Handle updating a user event
    case UPDATE_USER_EVENT:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_USER_EVENT_SUCCESS:
      return {
        ...state,
        usersEvents: {
          ...state.usersEvents,
          [action.payload.user._id]: state.usersEvents[action.payload.user._id].map(event =>
            event._id === action.payload._id ? action.payload : event
          ),
        },
        loading: false,
      };

    case UPDATE_USER_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // Handle deleting a user event
    case DELETE_USER_EVENT:
      return {
        ...state,
        loading: true,
      };

    case DELETE_USER_EVENT_SUCCESS:
      return {
        ...state,
        usersEvents: {
          ...state.usersEvents,
          [action.payload.user._id]: state.usersEvents[action.payload.user._id].filter(
            event => event._id.toString() !== action.payload._id.toString()
          ),
        },
        loading: false,
      };

    case DELETE_USER_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // Handle updating a Thomas user
    case UPDATE_THOMAS_USER:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_THOMAS_USER_SUCCESS:
      const updatedUsers = state.users.map(user => {
        if (user._id === action.payload._id) {
          return action.payload;
        } else {
          return user;
        }
      });

      return {
        ...state,
        users: updatedUsers,
        loading: false,
      };

    case UPDATE_THOMAS_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    // Handle deleting a Thomas user
    case DELETE_THOMAS_USER:
      return {
        ...state,
        loading: true,
      };

    case DELETE_THOMAS_USER_SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          data: state.tableData.data.filter(
            user => user.id.toString() !== action.payload.toString()
          ),
        },
        loading: false,
      };

    case DELETE_THOMAS_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case SELECT_THOMAS_USER:
      return {
        ...state,
        loading: true,
      };

    case SELECT_THOMAS_USER_SUCCESS:
      return {
        ...state,
        selectedUserId: action.payload._id,
        loading: false,
      };

    case SELECT_THOMAS_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ACCEPT_USER_INTO_EVENT:
      return {
        ...state,
        acceptUserLoading: true,
        acceptUserError: null,
      };

    case ACCEPT_USER_INTO_EVENT_SUCCESS:
      return {
        ...state,
        acceptUserLoading: false,
        usersEvents: {
          ...state.usersEvents,
          [action.payload.user._id]: state.usersEvents[action.payload.user._id].map((event) =>
            event._id === action.payload.event._id ? action.payload.event : event
        )},
      };

    case ACCEPT_USER_INTO_EVENT_FAIL:
      return {
        ...state,
        acceptUserLoading: false,
        acceptUserError: action.payload,
      };

    case GET_NEARBY_EVENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_NEARBY_EVENTS_SUCCESS:
      return {
        ...state,
        usersEventsNearby: {
          ...state.usersEventsNearby,
          [action.payload.userId]: action.payload.events,
        },
        loading: false,
      };
    case GET_NEARBY_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default thomas;
