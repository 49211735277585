import React, { useState } from 'react';
import { Form, Row, Col, Label, Input, FormFeedback, Button, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addUserEvent } from '../../store/thomas/actions';
import { generateEventDetails, prepareEventData } from '../../helpers/chat/chatGPT/generateEventDetails';

const NewEventForm = ({ onSave, user }) => {
  const dispatch = useDispatch();
  const [loadingGPT, setLoadingGPT] = useState(false);

  const onFormSubmit = async (values) => {
    if (!values?.title) return;

    const eventData = await prepareEventData(user, values);
    dispatch(addUserEvent({ event: eventData, user }));

    onSave();
    validation.resetForm();
  };

  const handleCompleteWithChatGPT = async () => {
    setLoadingGPT(true);

    try {
       const eventDetails = await generateEventDetails(user);

      // Example of setting returned values
      validation.setFieldValue('title', eventDetails.title || 'Generated Event Title');
      validation.setFieldValue('description', eventDetails.description || 'Generated Event Description');
    } catch (error) {
      console.error('ChatGPT error:', error);
    } finally {
      setLoadingGPT(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Please Enter Event Title'),
      description: Yup.string().required('Please Enter Event Description'),
    }),
  });

  const handleCancel = () => {
    if (typeof onSave === 'function') {
      onSave(null);
    } else {
      console.error("onSave is not a function or is undefined");
    }
  };

  return (
    <Form
      className="new-event-form elegant-form"
      onSubmit={async (e) => {
        e.preventDefault();
        await onFormSubmit(validation.values);
        return false;
      }}
    >
      <Row className="mb-4">
        <Col xs={12}>
          <Label className="form-label">Event Title</Label>
          <Input
            name="title"
            type="text"
            placeholder="Enter Event Title"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.title || ''}
            invalid={validation.touched.title && validation.errors.title ? true : false}
            className="elegant-input"
          />
          {validation.touched.title && validation.errors.title ? (
            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
          ) : null}
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={12}>
          <Label className="form-label">Event Description</Label>
          <Input
            name="description"
            type="textarea"
            placeholder="Enter Event Description"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ''}
            className="elegant-input"
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
          ) : null}
        </Col>
      </Row>

      <Row className="justify-content-end mt-4">
        <Col className="d-flex justify-content-start">
          <Button
            type="button"
            color="info"
            onClick={handleCompleteWithChatGPT}
            disabled={loadingGPT}
            className="me-3 elegant-button"
          >
            {loadingGPT ? <Spinner size="sm" /> : <i className="bx bx-brain"></i>} Fulfill with ChatGPT
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            type="button"
            color="secondary"
            className="me-2 elegant-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="success"
            className="elegant-button"
            disabled={loadingGPT}
          >
            Save Event
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default NewEventForm;
