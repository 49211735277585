import React from "react";
import { getEvents } from "../../store/actions";
import { formatDate } from '../../utils/dates';
import {
  SelectColumnFilter,
  NoneSomeFilter,
  IsActiveFilter,
  filterIsActive,
  filterNoneSome
} from '../../components/Common/filters';
import {
  ShortenedIdCell,
  StatusCell,
  ThinCell
} from '../../components/Common/Cells';
import withList from '../HOC/withList';

const createColumns = () => [
  {
    Header: 'ID',
    accessor: '_id',
    Cell: ShortenedIdCell,
  },
  {
    Header: 'City',
    accessor: 'city',
    Cell: ThinCell,
  },
  {
    Header: 'Title',
    accessor: 'title',
    Cell: ThinCell,
  },
  {
    Header: 'Creator User ID',
    accessor: row => row.createdBy?.user?._id ?? 'Unknown',
    Cell: ({ value, row }) => {
      // Get the active users for the event and find the data of the user with the given ID, use the name
      const users = row.original.users?.active ?? [];
      const user = users.find(u => u._id === value);

      return (
        <div>
          {user ? user.name : value}
        </div>
      );
    },
  },
  {
    Header: 'Active Users',
    accessor: row => row.users?.active?.length ?? 0,
    Cell: ({ value }) => {
      return (
        <div style={{
          backgroundColor: value > 0 ? 'lightgreen' : 'none',
          textAlign: 'center',
          padding: '5px',
          borderRadius: '5px',
        }}>
          {value}
        </div>
      );
    },
    Filter: NoneSomeFilter,
    filter: filterNoneSome,
  },
  {
    Header: 'Waiting Users',
    accessor: row => row.users?.waitList?.length ?? 0,
    Cell: ({ value }) => {
      return (
        <div style={{
          backgroundColor: value > 0 ? 'lightgreen' : 'none',
          textAlign: 'center',
          padding: '5px',
          borderRadius: '5px',
        }}>
          {value}
        </div>
      );
    },
    Filter: NoneSomeFilter,
    filter: filterNoneSome,
  },
  {
    Header: 'Chat',
    accessor: 'chatId',
    Cell: ({ value }) => <StatusCell value={value ? 'True' : 'False'} />,
    Filter: IsActiveFilter,
    filter: filterIsActive,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: StatusCell,
    Filter: SelectColumnFilter,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) => (
      <div style={{ minWidth: '130px' }}>
        {formatDate(new Date(value).getTime())}
      </div>
    ),
    Filter: () => null,
  }
];

export default withList(getEvents, 'events', createColumns, "/event");
