import { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { sendChatHelperMessage } from '../helpers/chat/chatGPT/sendChatHelperMessage';
import { MIN_REPLY_DELAY, MAX_REPLY_DELAY, COOLDOWN_PERIOD } from '../config';
import { sendMessage } from '../helpers/chat/chatGPT/chatManagement';
import { useSelector } from 'react-redux';

const useChatGPT = ({ chatId, userId, userProfile, otherUsers, onReply }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentAction, setCurrentAction] = useState(null);
  const [isReplying, setIsReplying] = useState(false);
  const [cooldownRemaining, setCooldownRemaining] = useState(0); // New state for cooldown
  const cooldownRef = useRef(null);
  const lastReplyTimestampRef = useRef(0);
  const { usersEvents } = useSelector((state) => state.thomas);
  const events = usersEvents[userId];

  useEffect(() => {
    return () => {
      if (cooldownRef.current) {
        clearInterval(cooldownRef.current);
      }
    };
  }, []);

  const startCooldown = useCallback(() => {
    setCooldownRemaining(COOLDOWN_PERIOD);

    cooldownRef.current = setInterval(() => {
      setCooldownRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(cooldownRef.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, []);

  const generateChatGPTReply = useCallback(async (conversation) => {
    if (!events) return;

    try {
      const messagesForAPI = [
        ...conversation.map(msg => ({
          role: msg.sentBy === userId ? 'user' : 'assistant',
          content: msg.text,
        })),
      ];

      const eventData = events.find(event => event.chatId === chatId);
      console.log('eventData:', eventData);

      const response = await sendChatHelperMessage(messagesForAPI, userProfile, eventData);

      if (response && response.response && typeof response.response === 'object' && response.response.text) {
        return response.response.text;
      } else if (response && typeof response === 'string') {
        return response;
      } else if (response && typeof response === 'object' && response.text) {
        return response.text;
      } else if (response && response.response && typeof response.response === 'string') {
        return response.response;
      }

      console.error('Invalid response format from assistant:', response);
      return null;
    } catch (error) {
      console.error('Failed to generate ChatGPT reply:', error);
      return null;
    }
  }, [userId, sendChatHelperMessage, chatId, events, userProfile]);

  const considerReplyingToMessage = useCallback(async (message, allMessages) => {
    if (isReplying || cooldownRemaining > 0) return;

    // Only reply to messages from other users
    if (message.sentBy === userId || message.sentBy === 'assistant') return;

    const now = Date.now();

    // Check if enough time has passed since the last reply
    if (now - lastReplyTimestampRef.current < COOLDOWN_PERIOD * 1000) {
      console.warn('Cooldown active. Skipping reply.');
      return;
    }

    // Update the last reply timestamp and start cooldown
    lastReplyTimestampRef.current = now;
    startCooldown();

    // Decide whether to reply (e.g., 75% chance)
    const shouldReply = Math.random() < 0.75;
    if (!shouldReply) {
      setCurrentAction('Assistant decided not to reply.');
      return;
    }

    setIsReplying(true);

    // Introduce a random delay between MIN_REPLY_DELAY and MAX_REPLY_DELAY seconds
    const delayInSeconds = Math.floor(Math.random() * (MAX_REPLY_DELAY - MIN_REPLY_DELAY + 1)) + MIN_REPLY_DELAY;

    setCurrentAction(`Assistant will reply in ${delayInSeconds} seconds...`);

    setTimeout(async () => {
      try {
        const reply = await generateChatGPTReply(allMessages);

        if (reply) {
          await sendMessage(chatId, userId, reply); // Corrected usage
          setCurrentAction('Assistant sent a reply.');
        } else {
          setCurrentAction('Assistant failed to generate a reply.');
        }
      } catch (err) {
        console.error('Error generating reply:', err);
        setCurrentAction('Assistant encountered an error while generating a reply.');
      } finally {
        setIsReplying(false);
      }
    }, delayInSeconds * 1000);
  }, [isReplying, cooldownRemaining, userId, startCooldown, generateChatGPTReply, sendMessage, chatId]);

  return {
    isLoading,
    error,
    currentAction,
    cooldownRemaining,
    sendMessage,
    considerReplyingToMessage,
  };
};

useChatGPT.propTypes = {
  chatId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  otherUsers: PropTypes.object.isRequired,
  onReply: PropTypes.func,
};

export default useChatGPT;
