import { get, post, put, del } from './api_helper';
import * as url from "./url_helper";

export const getUsers = ({filters, pageSize, pageIndex, sortBy}) => {
  const params = new URLSearchParams();

  // Add filters to the params if it's an object and not empty
  if (filters && Object.keys(filters).length > 0) {
    Object.entries(filters)?.forEach(([key, value]) => {
      if (value !== undefined) {
        params.append(key, value);
      }
    });
  }

  // Add pageSize, pageIndex, and sortBy to the params if they are defined
  if (pageSize !== undefined && pageSize > 100) params.append('pageSize', pageSize);
  if (pageIndex !== undefined && pageIndex > 0) params.append('pageIndex', pageIndex);
  if (sortBy !== undefined) params.append('sortBy', JSON.stringify(sortBy));

  const query = params.toString();
  return get(`${url.GET_USERS}${query ? `?${query}` : ''}`);
};

export const getUserProfile = userId => get(`${url.GET_USER_PROFILE}/${userId}`);
export const addNewUser = user => post(url.ADD_NEW_USER, user);
export const updateUser = user => put(`${url.UPDATE_USER}/${user._id}`, user);
export const deleteUser = userId => del(`${url.DELETE_USER}/${userId}`);

export const getUserById = userId => get(`${url.GET_USER_BY_ID}/${userId}`);