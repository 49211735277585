/* THOMAS_USERS */
export const GET_THOMAS_USERS = "GET_THOMAS_USERS";
export const GET_THOMAS_USERS_SUCCESS = "GET_THOMAS_USERS_SUCCESS";
export const GET_THOMAS_USERS_FAIL = "GET_THOMAS_USERS_FAIL";

/* THOMAS_USERS PROFILE */
export const GET_THOMAS_USER_PROFILE = "GET_THOMAS_USER_PROFILE";
export const GET_THOMAS_USER_PROFILE_SUCCESS = "GET_THOMAS_USER_PROFILE_SUCCESS";
export const GET_THOMAS_USER_PROFILE_FAIL = "GET_THOMAS_USER_PROFILE_FAIL";

/* Add user */
export const ADD_NEW_THOMAS_USER = "ADD_NEW_THOMAS_USER";
export const ADD_THOMAS_USER_SUCCESS = "ADD_THOMAS_USER_SUCCESS";
export const ADD_THOMAS_USER_FAIL = "ADD_THOMAS_USER_FAIL";

/* Edit user */
export const UPDATE_THOMAS_USER = "UPDATE_THOMAS_USER";
export const UPDATE_THOMAS_USER_SUCCESS = "UPDATE_THOMAS_USER_SUCCESS";
export const UPDATE_THOMAS_USER_FAIL = "UPDATE_THOMAS_USER_FAIL";

/* Delete user */
export const DELETE_THOMAS_USER = "DELETE_THOMAS_USER";
export const DELETE_THOMAS_USER_SUCCESS = "DELETE_THOMAS_USER_SUCCESS";
export const DELETE_THOMAS_USER_FAIL = "DELETE_THOMAS_USER_FAIL";

/* User Events */
export const GET_USER_EVENTS = "GET_USER_EVENTS";
export const GET_USER_EVENTS_SUCCESS = "GET_USER_EVENTS_SUCCESS";
export const GET_USER_EVENTS_FAIL = "GET_USER_EVENTS_FAIL";

/* Add Event */
export const ADD_USER_EVENT = "ADD_USER_EVENT";
export const ADD_USER_EVENT_SUCCESS = "ADD_USER_EVENT_SUCCESS";
export const ADD_USER_EVENT_FAIL = "ADD_USER_EVENT_FAIL";

/* Update Event */
export const UPDATE_USER_EVENT = "UPDATE_USER_EVENT";
export const UPDATE_USER_EVENT_SUCCESS = "UPDATE_USER_EVENT_SUCCESS";
export const UPDATE_USER_EVENT_FAIL = "UPDATE_USER_EVENT_FAIL";

/* Delete Event */
export const DELETE_USER_EVENT = "DELETE_USER_EVENT";
export const DELETE_USER_EVENT_SUCCESS = "DELETE_USER_EVENT_SUCCESS";
export const DELETE_USER_EVENT_FAIL = "DELETE_USER_EVENT_FAIL";

export const SELECT_THOMAS_USER = 'SELECT_THOMAS_USER';
export const SELECT_THOMAS_USER_SUCCESS = 'SELECT_THOMAS_USER_SUCCESS';
export const SELECT_THOMAS_USER_FAIL = 'SELECT_THOMAS_USER_FAIL';

/* Accept User Into Event */
export const ACCEPT_USER_INTO_EVENT = "ACCEPT_USER_INTO_EVENT";
export const ACCEPT_USER_INTO_EVENT_SUCCESS = "ACCEPT_USER_INTO_EVENT_SUCCESS";
export const ACCEPT_USER_INTO_EVENT_FAIL = "ACCEPT_USER_INTO_EVENT_FAIL";

export const GET_NEARBY_EVENTS = 'GET_NEARBY_EVENTS';
export const GET_NEARBY_EVENTS_SUCCESS = 'GET_NEARBY_EVENTS_SUCCESS';
export const GET_NEARBY_EVENTS_FAIL = 'GET_NEARBY_EVENTS_FAIL';

