import { jwtDecode } from 'jwt-decode';
import { axiosAPPApi } from './api_helper';
import { updateUser } from './thomas_helper';

/**
 * Checks if the access token is expired.
 * @param {string} token - The JWT access token.
 * @returns {boolean} - True if expired, false otherwise.
 */
const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true; // Assume expired if there's an error
  }
};

/**
 * Retrieves a valid access token for a specific user.
 * @param {Object} user - The user object containing accessToken, refreshToken, and other details.
 * @returns {Promise<string>} - The valid access token.
 * @throws Will throw an error if the refresh fails.
 */
export const getValidAccessToken = async (user) => {
  // console.log('getValidAccessToken', user);

  if (!user) {
    throw new Error('User object is required.');
  }

  // Check if accessToken exists and is not expired
  if (user.accessToken && !isTokenExpired(user.accessToken)) {
    return user.accessToken;
  }

  // If accessToken is missing or expired, attempt to refresh
  if (!user.refreshToken) {
    throw new Error('No refresh token available for the user.');
  }

  try {
    // The password is not hashed as from the app?
    const response = await axiosAPPApi.post('/login', {
      email: user.email,
      password: user.password,
      isThomas: true,
    });

    // const response = await axiosAPPApi.post('/refresh/token', {
    //   refreshToken: user.refreshToken,
    // }, {
    //   headers: {
    //     "sc-uxr-jtkew": user.refreshToken,
    //   },
    // });

    const { accessToken, refreshToken } = response.data;

    if (!accessToken) {
      throw new Error('No access token returned from refresh.');
    }

    // Update the user object with the new tokens
    user.accessToken = accessToken;
    user.refreshToken = refreshToken;

    // Update the user in the database
    await updateUser(user);

    // Optionally, update the user in your admin panel's state or storage
    // For example, if using React Context or Redux, dispatch an action to update the user
    console.log('User tokens updated:', accessToken);

    return accessToken;
  } catch (error) {
    console.error('Error refreshing access token:', error.response?.data || error.message);
    throw new Error('Failed to refresh access token.');
  }
};