let googleMapsScriptLoadingPromise;

const loadGoogleMapsScript = (apiKey, libraries = ['places']) => {
  if (googleMapsScriptLoadingPromise) {
    return googleMapsScriptLoadingPromise;
  }

  googleMapsScriptLoadingPromise = new Promise((resolve, reject) => {
    if (typeof window.google !== 'undefined' && window.google.maps) {
      // Google Maps already loaded
      resolve(window.google.maps);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(',')}`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      if (window.google && window.google.maps) {
        resolve(window.google.maps);
      } else {
        reject(new Error('Google Maps SDK loaded but `google.maps` is undefined.'));
      }
    };

    script.onerror = (error) => {
      reject(error);
    };

    document.head.appendChild(script);
  });

  return googleMapsScriptLoadingPromise;
};

export default loadGoogleMapsScript;
