import { languageDescriptions } from "./helper";

/**
 * Generates the message content for creating a new user profile.
 * Returns an array of message parts as expected by the Threads API.
 */
export const thomasPrompt_newUser = (lang, currentLocation) => {
  const languageKey = lang;
  const language = languageDescriptions[languageKey] || "Spanish";

  const promptParameters = {
    language: language,
    currentLocation: currentLocation,
    // Include interests and languages if needed
    // interests: interestsArray,
    // languages: languagesArray,
  };

  return [
    {
      type: "text",
      text: `Create a new user profile with the following parameters: ${JSON.stringify(promptParameters)}`
    }
  ];
};
