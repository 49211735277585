import React, { useEffect, useState, useRef } from "react";
import { Button, Spinner, Input, Form, FormGroup } from "reactstrap";
import { database } from "../../helpers/firebaseInit";
import { ref, onChildAdded, off } from "firebase/database";
import { IMAGE_BASE_URL } from "../../config";
import { createChat, saveChatIdOnEvent } from "../../helpers/chat/chatGPT/chatManagement";
import useChatGPT from "../../hooks/useChatGPT";
import { useNavigate } from "react-router-dom";
import { ACCEPT_USER_INTO_EVENT } from "../../store/thomas/actionTypes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const actionNames = {
  thinkingIfSendingMessage: (seconds) =>
    `Thinking if sending message in ${seconds} seconds...`,
  willSendMessage: (seconds) =>
    `Assistant will send the first message in ${seconds} seconds...`,
  thinkingIfAccepting: (user, seconds) =>
    `Thinking if accepting ${user.firstName} ${user.lastName} in ${seconds} seconds...`,
  accepting: (user) => `Accepting ${user.firstName}...`,
  accepted: (user) => `Accepted ${user.firstName}`,
  failedToAccept: (user) => `Failed to accept ${user.firstName}`,
  decidedNotToAccept: (user) => `Decided not to accept ${user.firstName}`,
};

const ThomasUserEventDetail = ({
  event,
  userId,
  currentUser,
  handleEditEvent,
  handleDeleteEvent,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eventData, setEventData] = useState(event);
  const prevEventRef = useRef(event);
  const [chatMessages, setChatMessages] = useState([]);
  const [loadingChat, setLoadingChat] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [creatingChat, setCreatingChat] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const messagesEndRef = useRef(null);
  const [userMap, setUserMap] = useState({});
  const [actionMessage, setActionMessage] = useState(null);
  const [acceptUserLoading, setAcceptUserLoading] = useState(false);
  const [acceptingUserId, setAcceptingUserId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [lastBotMessageTime, setLastBotMessageTime] = useState(null);

  // ChatGPT Hook with cooldown
  const {
    isLoading: isChatGPTLoading,
    error: chatGPTError,
    currentAction,
    cooldownRemaining,
    sendMessage: sendChatGPTMessage,
    considerReplyingToMessage,
  } = useChatGPT({
    chatId: eventData.chatId,
    userId,
    userProfile: currentUser,
    otherUsers: userMap,
    onReply: (assistantMessage) => {
      setChatMessages((prev) => [...prev, assistantMessage]);
    },
  });

  // Update eventData when the event prop changes
  useEffect(() => {
    setEventData(event);
  }, [event]);

  // Detect changes in eventData
  useEffect(() => {
    if (prevEventRef.current) {
      const prevWaitListIds = prevEventRef.current.users.waitList.map(
        (user) => user._id
      );
      const currentWaitListIds = eventData.users.waitList.map(
        (user) => user._id
      );

      const newUserIds = currentWaitListIds.filter(
        (id) => !prevWaitListIds.includes(id)
      );

      if (newUserIds.length > 0) {
        newUserIds.forEach((newUserId) => {
          const newUser = eventData.users.waitList.find(
            (user) => user._id === newUserId
          );
          handleNewUserInWaitList(newUser);
        });
      }
    }

    const map = {};
    eventData.users.active.forEach((user) => {
      map[user._id] = user;
    });
    setUserMap(map);

    prevEventRef.current = eventData;
  }, [eventData]);

  // Subscribe to chat messages
  useEffect(() => {
    if (eventData.chatId && userId && !loadingChat) {
      setLoadingChat(true);
      setChatMessages([]);

      const messagesRef = ref(database, `messages/${eventData.chatId}`);
      console.log("Subscribing to chat messages...", messagesRef);

      const handleChildAdded = (snapshot) => {
        const newMessage = snapshot.val();
        setChatMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, newMessage];

          if (newMessage.sentBy !== userId) {
            considerReplyingToMessage(newMessage, updatedMessages);
          }
          console.log("Chat messages updated:", updatedMessages);

          return updatedMessages;
        });
        setLoadingChat(false);
      };

      onChildAdded(messagesRef, handleChildAdded);

      return () => {
        off(messagesRef, "child_added", handleChildAdded);
      };
    }
  }, [eventData.chatId, userId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  // Automatic message sending
  useEffect(() => {
    if (
      !eventData.chatId &&
      eventData.users.active.length >= 2 &&
      currentUser
    ) {
      const randomDelay = Math.floor(Math.random() * 25) + 5;
      setCountdown(randomDelay);

      setActionMessage(actionNames.willSendMessage(randomDelay));

      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            setActionMessage(actionNames.willSendMessage(prevCountdown - 1));
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            return 0;
          }
        });
      }, 1000);

      const timeoutId = setTimeout(() => {
        sendChatMessage();
      }, randomDelay * 1000);

      return () => {
        clearInterval(countdownInterval);
        clearTimeout(timeoutId);
      };
    }
  }, [eventData.chatId, eventData.users.active.length, currentUser]);

  const handleNewUserInWaitList = (newUser) => {
    const delayInSeconds = Math.floor(Math.random() * 30) + 1;

    setActionMessage(
      actionNames.thinkingIfAccepting(newUser, delayInSeconds)
    );

    setTimeout(async () => {
      const shouldAcceptUser = Math.random() < 0.85;
      if (shouldAcceptUser) {
        setActionMessage(actionNames.accepting(newUser));

        try {
          await acceptUser(eventData._id, newUser._id);

          setActionMessage(actionNames.accepted(newUser));

          setTimeout(() => {
            setActionMessage(null);
          }, 5000);

          if (!eventData.chatId && eventData.users.active.length + 1 >= 2) {
            sendChatMessage();
          }
        } catch (error) {
          console.error(`Failed to accept user ${newUser.firstName}:`, error);
          setActionMessage(actionNames.failedToAccept(newUser));
          setTimeout(() => {
            setActionMessage(null);
          }, 5000);
        }
      } else {
        setActionMessage(actionNames.decidedNotToAccept(newUser));
        setTimeout(() => {
          setActionMessage(null);
        }, 5000);
      }
    }, delayInSeconds * 1000);
  };

  const acceptUser = async (eventId, userIdToAccept) => {
    setAcceptingUserId(userIdToAccept);
    setAcceptUserLoading(true);
    console.log("Accepting user", currentUser);

    try {
      dispatch({
        type: ACCEPT_USER_INTO_EVENT,
        payload: {
          eventId: eventId,
          userId: userIdToAccept,
          user: currentUser
        }
      });
      setAcceptingUserId(null);
      setAcceptUserLoading(false);
    } catch (error) {
      console.error(`Failed to accept user ${userIdToAccept}:`, error);
      setAcceptingUserId(null);
      setAcceptUserLoading(false);
    }
  };

  const sendChatMessage = async (text) => {
    if (text && !text.trim()) return;

    setCreatingChat(true);

    try {
      let chatIdLocal = eventData.chatId;

      const chatUsers = eventData.users.active;

      if (!chatIdLocal) {
        const chatData = await createChat(userId, { users: chatUsers });
        console.log("Chat created:", chatData);

        chatIdLocal = chatData.key;

        await saveChatIdOnEvent(eventData._id, chatIdLocal);

        setEventData((prevData) => ({ ...prevData, chatId: chatIdLocal }));
      }

      await sendChatGPTMessage(text ? text.trim() : null);

      setLastBotMessageTime(Date.now());

      setCreatingChat(false);
    } catch (error) {
      console.error("Failed to send chat message:", error);
      setCreatingChat(false);
    }
  };



  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!messageText.trim()) return;
    await sendChatMessage(messageText);
    setMessageText("");
  };

  // Handle ChatGPT Errors
  useEffect(() => {
    if (chatGPTError) {
      setErrorMessage(chatGPTError);
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [chatGPTError]);

  return (
    <div className="event-detail">
      {/* Action Message */}
      {actionMessage && <div className="action-message">{actionMessage}</div>}

      {/* Error Message */}
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      {/* Current Action Display */}
      {currentAction && <div className="current-action">{currentAction}</div>}

      {cooldownRemaining > 0 && (
        <div className="cooldown-remaining">
          Cooldown remaining: {cooldownRemaining} seconds
        </div>
      )}

      <div className="event-actions">
        <Button
          color="primary"
          size="sm"
          className="me-2"
          onClick={() => handleEditEvent(eventData)}
        >
          <i className="bx bx-edit"></i>
        </Button>
        <Button
          color="danger"
          size="sm"
          onClick={() => handleDeleteEvent(eventData._id)}
        >
          <i className="bx bx-trash"></i>
        </Button>
      </div>
      <div className="event-info">
        <p>{eventData.description || "N/A"}</p>
        <p>{eventData.city || "N/A"}</p>

        {/* Users Active */}
        <p>
          <strong>Users Active:</strong>
        </p>
        <div className="users-active">
          {eventData.users.active.length > 0 ? (
            eventData.users.active.map((user) => (
              <div
                key={user._id}
                className="user-card"
                onClick={() => navigate(`/user/${user._id}`)}
              >
                <img
                  src={
                    user.profileImage &&
                    user.profileImage.includes("firebase")
                      ? user.profileImage
                      : user.profileImage
                      ? `${IMAGE_BASE_URL}/${user.profileImage}`
                      : "/default-profile.png"
                  }
                  alt={`${user.firstName} ${user.lastName}`}
                />
                <span>
                  {user.firstName} {user.lastName}
                </span>
              </div>
            ))
          ) : (
            <p>No active users</p>
          )}
        </div>

        {/* Users Waiting */}
        <p>
          <strong>Users Waiting:</strong>
        </p>
        <div className="users-waiting">
          {eventData.users.waitList.length > 0 ? (
            eventData.users.waitList.map((user) => (
              <div
                key={user._id}
                className="user-card"
                onClick={() => {
                  navigate(`/user/${user._id}`);
                }}
              >
                <img
                  src={
                    user.profileImage &&
                    user.profileImage.includes("firebase")
                      ? user.profileImage
                      : user.profileImage
                      ? `${IMAGE_BASE_URL}/${user.profileImage}`
                      : "/default-profile.png"
                  }
                  alt={`${user.firstName} ${user.lastName}`}
                />
                <span>
                  {user.firstName} {user.lastName}
                </span>
                <Button
                  color="success"
                  size="sm"
                  className="ms-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    acceptUser(eventData._id, user._id);
                  }}
                  disabled={
                    acceptingUserId === user._id && acceptUserLoading
                  }
                >
                  {acceptingUserId === user._id && acceptUserLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    "Accept"
                  )}
                </Button>
              </div>
            ))
          ) : (
            <p>No users waiting</p>
          )}
        </div>
      </div>

      {/* Chat Section */}
      {eventData.users.active.length >= 2 && (
        <div
          className="chat-section"
          style={{
            backgroundImage: `url("${eventData.chatBGImg}")`,
            backgroundSize: "cover",
            padding: "10px",
          }}
        >
          {loadingChat ? (
            <Spinner color="primary" />
          ) : eventData.chatId ? (
            <>
              <div className="chat-messages">
                {chatMessages.map((message, index) => {
                  const sender = userMap[message.sentBy];
                  const senderProfileImage = sender?.profileImage
                    ? sender.profileImage.includes("firebase")
                      ? sender.profileImage
                      : `${IMAGE_BASE_URL}/${sender.profileImage}`
                    : "/default-profile.png";
                  const senderName = `${sender?.firstName || "Unknown"} ${
                    sender?.lastName || ""
                  }`;

                  const messageText =
                    typeof message.text === "string" ? message.text : message.text.text;

                  return (
                    <div
                      key={index}
                      className={`chat-message ${
                        message.sentBy === userId ? "own-message" : "other-message"
                      }`}
                    >
                      {message.sentBy !== userId && (
                        <img
                          src={senderProfileImage}
                          alt={senderName}
                          className="message-avatar"
                        />
                      )}
                      <div className="message-content">
                        <div className="message-text">{messageText}</div>
                      </div>
                    </div>
                  );
                })}

                <div ref={messagesEndRef} />
              </div>
              {/* Message input */}
              <Form onSubmit={handleSendMessage} className="chat-input-form">
                <FormGroup className="d-flex">
                  <Input
                    type="text"
                    placeholder="Type your message..."
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!messageText.trim() || isChatGPTLoading}
                  >
                    {isChatGPTLoading ? <Spinner size="sm" /> : "Send"}
                  </Button>
                </FormGroup>
              </Form>
            </>
          ) : (
            <>
              {/* Countdown message */}
              {countdown !== null && countdown > 0 && (
                <div className="countdown-message">
                  {actionNames.willSendMessage(countdown)}
                </div>
              )}
              {/* Input to start chat */}
              <Form onSubmit={handleSendMessage} className="chat-input-form">
                <FormGroup className="d-flex">
                  <Input
                    type="text"
                    placeholder="Type your message..."
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!messageText.trim() || creatingChat}
                  >
                    {creatingChat ? <Spinner size="sm" /> : "Start Chat"}
                  </Button>
                </FormGroup>
              </Form>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ThomasUserEventDetail;
