import { ADMIN_BE_API_URL } from '../../../config';
import EventSourcePolyfill from 'eventsource-polyfill';
import { attemptToParseJSON } from '../../../utils/parseJSON';
import config from '../../../config';
import { calculateAge } from '../chatLogic';
import { languageMap } from '../../../utils/langMap';
import { assistantChatPrompt } from './assistantChatPrompt';
import { chatGPTAPI } from './chatGPTAPI';
/**
 * Interacts with the assistant via the backend API.
 * @param {Array} messages - The array of messages to send.
 * @param {string} assistantModelId - The ID of the assistant model.
 * @returns {Promise<string|null>} - The assistant's response as plain text.
 */
export async function interactWithAssistant(messages, user, event) {
  try {
    const participants = event.users.active;
    const participantsDataString = participants
      .map((u) => `${u.firstName} ${u.lastName}`)
      .join(', ') || 'No other participants yet';
    const lang = languageMap(user.lang || 'en');
    const messagesDataString = messages
      .filter(msg => msg.role === 'user')
      .map((msg) => `${msg.senderName}: "${msg.content}"`)
      .join('\n');

    const context = {
      FirstName: user.firstName,
      LastName: user.lastName,
      Age: calculateAge(user.dateOfBirth),
      Interests: user.interests ? user.interests.join(', ') : 'various topics',
      Participants: participantsDataString,
      Language: lang,
      EventLocation: event.city || event.location.coordinates || 'unknown',
      currentConversation: messagesDataString,
    };

    // Ensure messages are properly formatted
    const formattedMessages = messages.map((msg) => ({
      role: msg.role,
      content: msg.content, // content should be a string
    }));

    const assistant_prompt = assistantChatPrompt(context);

    const messagesToSend = [
      { 'role': 'system', 'content': assistant_prompt },
      ...formattedMessages,
    ];

     // Use the OpenAI ChatGPT API directly
     const response = await chatGPTAPI({ messages: messagesToSend }, user.id, false);

     if (!response) {
       throw new Error('No response from assistant');
     }

     return response.trim();

    // const response = await fetch(`${ADMIN_BE_API_URL}/e/assistants/interact`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     messages: messagesToSend,
    //     assistantModelId: config.assistant_reply_chat,
    //   }),
    // });

    // if (!response.ok) {
    //   const errorData = await response.json();
    //   console.error('Backend Error:', errorData.error);
    //   throw new Error(errorData.error || 'Failed to interact with assistant');
    // }

    // const data = await response.json();

    // let assistantResponse = data.response;

    // // Attempt to parse if the response is a JSON string
    // if (typeof assistantResponse === 'string') {
    //   const parsedResponse = attemptToParseJSON(assistantResponse);

    //   if (parsedResponse && typeof parsedResponse === 'object' && 'text' in parsedResponse) {
    //     assistantResponse = parsedResponse.text;
    //   } else {
    //     assistantResponse = assistantResponse;
    //   }
    // } else if (typeof assistantResponse === 'object' && 'text' in assistantResponse) {
    //   assistantResponse = assistantResponse.text;
    // }

    // // Ensure the response is a string
    // if (typeof assistantResponse !== 'string') {
    //   console.error('Assistant response is not a string:', assistantResponse);
    //   return null;
    // }

    // return assistantResponse.trim();
  } catch (error) {
    console.error(`Error interacting with assistant: ${error.message}`);
    return null;
  }
}

/**
 * Streams interactions with the assistant.
 * @param {Array} messages - The array of messages to send.
 * @param {string} assistantModelId - The ID of the assistant model.
 * @param {Function} onMessage - Callback for each message chunk received.
 * @param {Function} onEnd - Callback when streaming ends.
 * @param {Function} onError - Callback for errors during streaming.
 * @returns {Function} - A function to close the event source.
 */
export function interactWithAssistantStream(messages, onMessage, onEnd, onError) {
  const url = `${ADMIN_BE_API_URL}/assistants/interact-stream`; // Ensure the correct backend URL

  const eventSource = new EventSourcePolyfill(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ messages, assistantModelId: config.assistant_reply_chat }),
  });

  eventSource.onmessage = (event) => {
    const data = event.data;
    if (data === '[DONE]') {
      onEnd();
      eventSource.close();
      return;
    }

    onMessage(data);
  };

  eventSource.onerror = (err) => {
    console.error("Streaming error:", err);
    onError(err);
    eventSource.close();
  };

  return () => {
    eventSource.close();
  };
}

export const interactWithAssistantUserData = async (messages) => {
  try {
    const response = await fetch(`${ADMIN_BE_API_URL}/e/assistants/interact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages,
        assistantModelId: config.assistant_create_user,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Backend Error:', errorData.error);
      throw new Error(errorData.error || 'Failed to interact with assistant');
    }

    const data = await response.json();

    let assistantResponse = data.response;

    // Attempt to parse if the response is a JSON string
    if (typeof assistantResponse === 'string') {
      const parsedResponse = attemptToParseJSON(assistantResponse);
      assistantResponse = parsedResponse;
    } else if (typeof assistantResponse === 'object' && 'text' in assistantResponse) {
      assistantResponse = assistantResponse.text;
    }

    return assistantResponse;
  } catch (error) {
    console.error(`Error interacting with assistant: ${error.message}`);
    return null;
  }
}

