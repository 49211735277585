import { interactWithAssistant } from './interactWithAssistant';
// import { assistant_reply_chat } from '../../../config';

/**
 * Sends a chat message to the assistant and returns the response.
 * @param {Array} messages - The array of messages to send.
 * @param {boolean} json - Whether to parse the response as JSON.
 * @returns {Promise<Object|string|null>} - The assistant's response.
 */
export async function sendChatHelperMessage(messages, user, event) {
  return await interactWithAssistant(messages, user, event);
}
