import { database } from '../../firebaseInit';
import { ref, push, set, update, get } from 'firebase/database';
import { sendPushNotificationForUsers } from '../../notifications_helper';
import { axiosAPPApi } from '../../api_helper';

/**
 * Creates a new chat in Firebase.
 * @param {string} loggedInUserId - The ID of the user creating the chat.
 * @param {Object} chatData - The data for the new chat.
 * @returns {Promise<Object>} - The newly created chat data including the chat ID.
 */
export const createChat = async (loggedInUserId, chatData) => {
  const newChatData = {
    ...chatData,
    createdBy: loggedInUserId,
    updatedBy: loggedInUserId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  const chatsRef = ref(database, 'chats');
  const newChatRef = push(chatsRef);
  await set(newChatRef, newChatData);

  const chatId = newChatRef.key;

  // Add chat ID to each user's userChats
  const userChatsPromises = newChatData.users.map(async (user) => {
    console.log('Adding chat to user:', user._id);

    const userChatsRef = ref(database, `userChats/${user._id}`);
    const newUserChatRef = push(userChatsRef);
    await set(newUserChatRef, chatId);
  });

  await Promise.all(userChatsPromises);

  // Retrieve the newly created chat data
  const snapshot = await get(newChatRef);
  const data = snapshot.val();
  data.key = chatId;

  return data;
};

/**
 * Sends a message to a specific chat.
 * @param {string} chatId - The ID of the chat.
 * @param {string} senderId - The ID of the sender.
 * @param {string} messageText - The text of the message.
 * @param {string|null} imageUrl - (Optional) URL of an image.
 * @param {string|null} replyTo - (Optional) ID of the message being replied to.
 * @param {string|null} type - (Optional) Type of the message.
 * @returns {Promise<void>}
 */
export const sendMessage = async (
  chatId,
  senderId,
  messageText,
  imageUrl = null,
  replyTo = null,
  type = null
) => {
  const messagesRef = ref(database, `messages/${chatId}`);

  const messageData = {
    sentBy: senderId,
    sentAt: new Date().toISOString(),
    text: messageText,
  };

  if (replyTo) messageData.replyTo = replyTo;
  if (imageUrl) messageData.imageUrl = imageUrl;
  if (type) messageData.type = type;

  // Use push to add a new message and get its reference
  const newMessageRef = push(messagesRef);
  await set(newMessageRef, messageData);

  // Update chat's latest message
  const chatRef = ref(database, `chats/${chatId}`);
  await update(chatRef, {
    updatedBy: senderId,
    updatedAt: new Date().toISOString(),
    latestMessageText: messageText,
  });
};

/**
 * Sends a text message and triggers a push notification.
 * @param {string} chatId - The ID of the chat.
 * @param {Object} senderData - The sender's user data.
 * @param {string} messageText - The text of the message.
 * @param {string|null} replyTo - (Optional) ID of the message being replied to.
 * @param {Array} chatUsers - The users in the chat to notify.
 * @param {string|null} planId - (Optional) ID of the associated plan.
 * @returns {Promise<void>}
 */
export const sendTextMessage = async (
  chatId,
  senderData,
  messageText,
  replyTo = null,
  chatUsers = [],
  planId = null
) => {
  await sendMessage(chatId, senderData._id, messageText, null, replyTo, null);
  await sendPushNotificationForUsers(
    chatUsers,
    `${senderData.firstName} ${senderData.lastName}`,
    messageText,
    "chatMessage",
    planId
  );
};

/**
 * Adds a user to an existing chat.
 * @param {Object} userLoggedInData - The data of the user adding another user.
 * @param {Object} userToAdd - The user to be added to the chat.
 * @param {Object} chatData - The existing chat data.
 * @returns {Promise<void>}
 */
export const addUserToChat = async (
  userLoggedInData,
  userToAdd,
  chatData
) => {
  const userToAddId = userToAdd._id;
  const existingUsers = chatData.users || [];

  // Avoid adding the user if they're already in the chat
  if (existingUsers.find((u) => u._id === userToAddId)) {
    return;
  }

  const updatedUsers = [...existingUsers, userToAdd];

  // Update chat data
  await updateChatData(chatData.key, userLoggedInData._id, { users: updatedUsers });

  // Add chat to new user's userChats
  const userChatsRef = ref(`userChats/${userToAddId}`);
  await userChatsRef.push(chatData.key);

  // Send info message to chat
  const messageText = `${userLoggedInData.firstName} ${userLoggedInData.lastName} added ${userToAdd.firstName} ${userToAdd.lastName} to the chat.`;
  await sendInfoMessage(chatData.key, userLoggedInData._id, messageText);
};

/**
 * Updates chat data in Firebase.
 * @param {string} chatId - The ID of the chat.
 * @param {string} userId - The ID of the user making the update.
 * @param {Object} chatData - The data to update.
 * @returns {Promise<void>}
 */
export const updateChatData = async (chatId, userId, chatData) => {
  const chatRef = ref(`chats/${chatId}`);

  await chatRef.update({
    ...chatData,
    updatedAt: new Date().toISOString(),
    updatedBy: userId,
  });
};

/**
 * Sends an informational message to the chat.
 * @param {string} chatId - The ID of the chat.
 * @param {string} senderId - The ID of the sender.
 * @param {string} messageText - The text of the info message.
 * @returns {Promise<void>}
 */
export const sendInfoMessage = async (chatId, senderId, messageText) => {
  await sendMessage(chatId, senderId, messageText, null, null, "info");
};

/**
 * Retrieves chat data from Firebase.
 * @param {string} chatId - The ID of the chat.
 * @returns {Promise<Object|null>} - The chat data or null if not found.
 */
export const getChatData = async (chatId) => {
  try {
    const chatRef = ref(database, `chats/${chatId}`);
    const snapshot = await get(chatRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      data.key = snapshot.key;
      return data;
    } else {
      console.warn(`No chat data found for chatId: ${chatId}`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching chat data:', error);
    throw error;
  }
};

export const saveChatIdOnEvent = async (eventId, chatId) => {
  try {
    await axiosAPPApi.put(`/events/${eventId}`, { id: eventId, chatId });
  } catch (error) {
    console.error("Failed to save chatId on event:", error);
  }
};
