import { toast } from 'react-toastify';
import { interactWithAssistant } from './chatGPT/interactWithAssistant';
import { sendMessage } from './chatGPT/chatManagement';
import { ref, get } from 'firebase/database';
import { database } from '../firebaseInit';
import config from '../../config';

/**
 * Helper function to calculate age from date of birth.
 * @param {string} dateOfBirth - The user's date of birth.
 * @returns {number} - The calculated age.
 */
export const calculateAge = (dateOfBirth) => {
  const dob = new Date(dateOfBirth);
  const diffMs = Date.now() - dob.getTime();
  const ageDate = new Date(diffMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

/**
 * Helper function to get participant names excluding the current user.
 * @param {Object} event - The event object containing user data.
 * @param {Object} currentUser - The current user object.
 * @returns {string} - A comma-separated string of participant names.
 */
const getParticipantNames = (event, currentUser) => {
  const participants = event.users.active.filter((u) => u._id !== currentUser._id);
  return participants.map((u) => `${u.firstName} ${u.lastName}`).join(', ') || 'No other participants yet';
};

/**
 * Fetches all chat messages for a given chat ID from Firebase Realtime Database.
 * @param {string} chatId - The ID of the chat.
 * @returns {Promise<Array>} - An array of chat messages.
 */
export const fetchChatMessages = async (chatId) => {
  try {
    const messagesRef = ref(database, `messages/${chatId}`);
    const snapshot = await get(messagesRef);
    if (snapshot.exists()) {
      const messagesObj = snapshot.val();
      // Convert messages object to an array and sort by timestamp
      const messages = Object.keys(messagesObj)
        .map((key) => ({
          id: key,
          ...messagesObj[key],
        }))
        .sort((a, b) => new Date(a.sentAt) - new Date(b.sentAt));
      return messages;
    } else {
      console.warn(`No messages found for chatId: ${chatId}`);
      return [];
    }
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};

/**
 * Processes chats for a user by deciding whether to reply, write, or remain silent.
 * @param {Object} user - The user object containing user details.
 * @param {Array} events - Array of event objects associated with the user.
 * @param {string} chatId - The ID of the chat.
 * @returns {Promise<void>}
 */
export const checkChatsAndReply = async (user, event) => {
  try {
    const chatId = event.chatId;
    if (!chatId) {
      toast.warn(`No chat ID found for user ${user.firstName} ${user.lastName}.`);
      return;
    }

    // Fetch all chat messages
    const chatMessages = await fetchChatMessages(chatId);

    // Build the conversation history
    const messages = [];

    // Limit the number of messages to avoid overloading the assistant
    const recentMessages = chatMessages.slice(-10); // Get last 10 messages

    for (const message of recentMessages) {
      console.log('Message:', message);
      const sender = event.users.active.find((u) => u._id === message.sentBy);
      const senderName = sender ? `${sender.firstName} ${sender.lastName}` : 'Unknown';

      messages.push({
        role: message.sentBy === user._id ? 'assistant' : 'user',
        content: message.text,
        senderName
      });
    }

    // **Implement Message Sequence Check**
    const lastThreeMessages = recentMessages.slice(-3);
    const sameUserSentLastThree = lastThreeMessages.every(
      (msg) => msg.sentBy === user._id
    );

    if (sameUserSentLastThree) {
      console.info(`Assistant has already sent three messages in a row in chat ${chatId}. Skipping reply.`);
      return;
    }

    // Decide whether to reply or send a follow-up message
    let shouldInteract = false;
    const lastMessage = recentMessages[recentMessages.length - 1];

    if (!lastMessage || lastMessage.sentBy !== user._id) {
      // Another user was the last to speak or it's the first message
      shouldInteract = Math.random() < 0.85; // 85% chance to reply
    } else {
      // The bot was the last to speak
      shouldInteract = false; // Do not send another message
    }

    if (shouldInteract) {
      const assistantResponse = await interactWithAssistant(messages, user, event);

      if (assistantResponse) {
        await sendMessage(chatId, user._id, assistantResponse);
        toast.success(`Sent message for user ${user.firstName} ${user.lastName}.`);
      } else {
        toast.warn(`No message generated for user ${user.firstName} ${user.lastName}.`);
      }
    } else {
      toast.info(`Decided not to send a message for user ${user.firstName}.`);
    }
  } catch (error) {
    console.error(`Error in checkChatsAndReply for user ${user.firstName}:`, error);
    toast.error(`Failed to process chats for user ${user.firstName} ${user.lastName}.`);
  }
};


