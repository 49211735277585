// **Action Messages**
export const actionMessages = {
  initializing: 'Initializing Thomas AI...',
  processingUser: (firstName, lastName) => `Processing user ${firstName} ${lastName}`,
  failedToRefreshToken: (firstName, lastName) =>
    `Failed to refresh access token for ${firstName} ${lastName}`,
  processingWaitlist: (eventTitle, firstName) =>
    `Processing waitlist for event "${eventTitle}" for ${firstName}`,
  acceptedUser: (pendingFirstName, eventTitle) =>
    `Accepted user ${pendingFirstName} into event "${eventTitle}"`,
  failedToAcceptUser: (pendingFirstName, eventTitle) =>
    `Failed to accept user ${pendingFirstName} into event "${eventTitle}"`,
  decidedNotToAcceptUser: (pendingFirstName, eventTitle) =>
    `Decided not to accept user ${pendingFirstName} into event "${eventTitle}"`,
  decidedToCreateEvent: (firstName, lastName) =>
    `Decided to create event for ${firstName} ${lastName}`,
  generatingEventDetails: (firstName, lastName) =>
    `Generating event details for ${firstName} ${lastName}`,
  creatingEvent: (firstName, lastName) =>
    `Creating event for ${firstName} ${lastName}`,
  eventCreated: (eventTitle, firstName, lastName) =>
    `Event "${eventTitle}" created for ${firstName} ${lastName}`,
  failedToCreateEvent: (firstName, lastName) =>
    `Failed to create event for ${firstName} ${lastName}`,
  noEventDetails: (firstName) =>
    `No event details received for ${firstName}, cancelling`,
  decidedNotToCreateEvent: (firstName, lastName) =>
    `Decided not to create event for ${firstName} ${lastName}`,
  fetchingNearbyEvents: (firstName, lastName) =>
    `Fetching nearby events for ${firstName} ${lastName}`,
  noNearbyEvents: (firstName) => `No nearby events found for ${firstName}`,
  askingToJoinEvent: (firstName, eventTitle) =>
    `${firstName} is asking to join event "${eventTitle}"`,
  askedToJoinEvent: (firstName, eventTitle) =>
    `${firstName} asked to join event "${eventTitle}"`,
  failedToAskToJoinEvent: (firstName, eventTitle) =>
    `${firstName} failed to ask to join event "${eventTitle}"`,
  decidedNotToAskToJoin: (firstName) =>
    `${firstName} decided not to ask to join any events`,
  errorFetchingNearbyEvents: (firstName) =>
    `Error fetching nearby events for ${firstName}`,
  checkingChats: (firstName, lastName) =>
    `Checking chats for ${firstName} ${lastName}`,
  completedChatInteractions: (firstName, lastName) =>
    `Completed chat interactions for ${firstName} ${lastName}`,
  errorInteractingWithChats: (firstName) =>
    `Error interacting with chats for ${firstName}`,
  processCompleted: 'Thomas AI process completed. Starting again in 5 seconds...',
  thomasStopped: 'Thomas AI has been stopped.',
};