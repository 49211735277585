import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../../helpers/firebaseInit';
import { Button, Spinner } from 'reactstrap';
import { ObjectId } from 'bson';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ImageGalleryModal from './ImageGalleryModal';

const ImageUploader = ({
  userId,
  uploadedImages,
  setUploadedImages,
  maxFiles = 10,
  accept = 'image/*',
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const toggleModal = (imageIndex) => {
    setCurrentImageIndex(imageIndex);
    setModalOpen(!modalOpen);
  };

  // Handler for removing an image
  const handleRemoveImage = async (imageId) => {
    const imageToRemove = uploadedImages.find((img) => img._id === imageId);
    if (imageToRemove) {
      if (imageToRemove.src && imageToRemove.src.includes('firebasestorage.googleapis.com')) {
        try {
          // Extract the file path from the URL
          const decodedPath = decodeURIComponent(imageToRemove.src.split('/o/')[1].split('?')[0]);
          const imageRef = ref(storage, decodedPath);
          await deleteObject(imageRef);
          // Update the state
          setUploadedImages((prev) => prev.filter((img) => img._id !== imageId));
          toast.success('Image removed successfully.', { autoClose: 2000 });
        } catch (error) {
          console.error('Error deleting image:', error);
          toast.error('Failed to remove image.', { autoClose: 3000 });
        }
      } else {
        // If the image isn't in Firebase Storage, just remove it from state
        setUploadedImages((prev) => prev.filter((img) => img._id !== imageId));
        toast.success('Image removed from the list.', { autoClose: 2000 });
      }
    }
  };

  // Dropzone configuration
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (uploadedImages.length + acceptedFiles.length > maxFiles) {
        toast.error(`You can only upload up to ${maxFiles} images.`, { autoClose: 3000 });
        return;
      }

      acceptedFiles.forEach((file) => {
        const imageId = new ObjectId().toString();
        const imageObj = {
          _id: imageId,
          title: file.name,
          createdAt: Date.now(),
          uploading: true,
          src: '',
        };

        // Add the image to the state
        setUploadedImages((prev) => [...prev, imageObj]);

        // Create a storage reference
        const fileRef = ref(storage, `thomas/users/${userId}/pictures/${file.name}`);

        // Start the upload
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Optional: Implement progress tracking if needed
            // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            console.error('Error uploading file:', error);
            // Remove the image from state
            setUploadedImages((prev) => prev.filter((img) => img._id !== imageId));
            toast.error(`Failed to upload ${file.name}.`, { autoClose: 3000 });
          },
          () => {
            // Upload completed successfully, get the download URL
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                // Update the image in state
                setUploadedImages((prev) =>
                  prev.map((img) => {
                    if (img._id === imageId) {
                      return {
                        ...img,
                        src: downloadURL,
                        uploading: false,
                      };
                    }
                    return img;
                  })
                );
                toast.success(`${file.name} uploaded successfully.`, { autoClose: 2000 });
              })
              .catch((error) => {
                console.error('Error getting download URL:', error);
                toast.error(`Failed to retrieve URL for ${file.name}.`, { autoClose: 3000 });
              });
          }
        );
      });
    },
    [userId, uploadedImages, setUploadedImages, maxFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: true,
  });

  return (
    <div className="image-uploader">
      <div
        {...getRootProps()}
        className={`dropzone p-4 border rounded text-center ${
          isDragActive ? 'bg-primary text-white' : 'bg-light'
        }`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag & drop some files here, or click to select files</p>
        )}
      </div>

      {uploadedImages.length > 0 && (
        <div className="thumbnails d-flex flex-wrap gap-3">
          {uploadedImages.map((image, index) => (
            <div key={image._id} className="thumbnail position-relative">
              {image.uploading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ width: 150, height: 150 }}>
                  <Spinner color="primary" />
                </div>
              ) : (
                <img
                  src={image.src}
                  alt={image.title || `Image ${index + 1}`}
                  className="img-thumbnail"
                  onClick={() => toggleModal(index)}
                />
              )}
              <Button
                color="danger"
                size="sm"
                className="position-absolute top-0 end-0 m-1 p-0"
                style={{ borderRadius: '50%' }}
                onClick={() => handleRemoveImage(image._id)}
              >
                <i className="bx bx-trash"></i>
              </Button>
            </div>
          ))}
        </div>
      )}

      {/* Modal for Image Preview */}
      <ImageGalleryModal
        isOpen={modalOpen}
        toggle={toggleModal}
        images={uploadedImages}
        currentIndex={currentImageIndex}
        setCurrentIndex={setCurrentImageIndex}
      />
    </div>
  );
};

ImageUploader.propTypes = {
  userId: PropTypes.string.isRequired,
  uploadedImages: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string,
      createdAt: PropTypes.number,
      uploading: PropTypes.bool,
      src: PropTypes.string,
    })
  ).isRequired,
  setUploadedImages: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  accept: PropTypes.string,
};

export default ImageUploader;
