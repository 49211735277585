import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import loadGoogleMapsScript from '../../utils/loadGoogleMapsScript';
import { googleMapsAPIKey } from '../../config';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

const GooglePlaces = ({ onPress, onBlur, onFocus, placeholder, initialValue }) => {
  const [googleLoaded, setGoogleLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;

    loadGoogleMapsScript(googleMapsAPIKey, ['places'])
      .then(() => {
        if (isMounted) {
          setGoogleLoaded(true);
        }
      })
      .catch((error) => {
        console.error('Error loading Google Maps script:', error);
        toast.error('Failed to load location services.', { autoClose: 3000 });
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {googleLoaded ? (
        <GooglePlacesAutocomplete
          selectProps={{
            onInputChange: onFocus,
            onBlur: onBlur,
            onChange: onPress,
            placeholder: initialValue || placeholder || "Enter a location",
          }}
          styles={{
            container: {
              zIndex: 9999,
              maxHeight: '70px',
            },
            textInputContainer: {
              borderTopWidth: 0,
              borderBottomWidth: 0,
              overflow: 'visible',
              zIndex: 1,
            },
            textInput: {
              paddingVertical: '15px',
              paddingHorizontal: '10px',
              borderWidth: '1px',
              borderRadius: '6px',
              height: '50px',
              borderColor: '#cccccc',
            },
            predefinedPlacesDescription: {
              borderWidth: '1px',
              borderColor: '#cccccc',
            },
            listView: {
              top: '45.5px',
              zIndex: 99999,
              position: 'absolute',
              borderRadius: '6px',
              maxHeight: '300px',
              borderColor: '#cccccc',
              borderWidth: '1px',
            },
          }}
          query={{
            key: googleMapsAPIKey,
            language: 'en'
          }}
          requestOptions={{
            componentRestrictions: { country: 'us' }, // Optional: Restrict to specific country
          }}
          debounce={300}
        />
      ) : (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          <Spinner size="sm" color="primary" /> Loading location services...
        </div>
      )}
    </>
  );
};

GooglePlaces.propTypes = {
  onPress: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
};

export default GooglePlaces;
